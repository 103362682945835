import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { unSetDefinitionItem, setCurrentContextCategoryFeedback, putTimeBreathing, breathActionStop, setBreathTiming } from '../../actions/actions'


import BreathIndicator from '../breath/breathIndicator'

let interval = null;
let _promise = null;

class DefinitionBreath extends Component {

    constructor(props) {
        super(props);
        this.state = {
            context: {},
            category: {},
            doit: false,
            initBreathProscess: false,
            configBreathDefaultTime: 0
        };

        this.setCurrentContextCategory = this.setCurrentContextCategory.bind(this);
        this.initBreathing = this.initBreathing.bind(this);

        this.changeTimingBreath = this.changeTimingBreath.bind(this);

        this.timeSelect = createRef();

    }

    componentDidMount() {
        const { definitionItem, contexts, actor, history, configBreathDefault } = this.props;


        if (!actor.id) {
            history.push("/");
        }

        let ctxFeedBack = contexts.filter(context => context.id === definitionItem.contextId)
            .map(context => {
                return {
                    ...context,
                    category: context.p_feedback_categories
                        .filter(category => category.id === definitionItem.categoryId).reduce((a, b) => b, {})
                }
            }).reduce((a, b) => b, {})

        this.setState({
            context: ctxFeedBack,
            category: ctxFeedBack.category,
            countBreathing: 0,
            configBreathDefaultTime: configBreathDefault.time
        })
    }



    changeTimingBreath(val) {
        const { setBreathTiming } = this.props;
        setBreathTiming(val);
    }

    initBreathing() {
        const { definitionItem, putTimeBreathing } = this.props;
        this.setState({
            initBreathProscess: true
        })

        if (definitionItem.currentBreathing) {
            new Promise((resolve) => {
                _promise = resolve;
                interval = setInterval(() => {
                    let _seconds = (this.state.configBreathDefaultTime * 60)
                    if (_seconds <= this.state.countBreathing) {
                        clearInterval(interval);
                        resolve()
                    } else {
                        if (this.state.countBreathing !== 0 && this.state.countBreathing % (_seconds / 6) === 0) {
                            putTimeBreathing(_seconds / 6)
                        }
                        this.setState({
                            countBreathing: this.state.countBreathing + 1
                        })
                    }
                }, 1000)
            }).then(() => this.setState({
                doit: true
            }))
        }
    }


    setCurrentContextCategory(context, category) {
        if (interval) {
            _promise();
            clearInterval(interval);
        }

        this.setState({
            doit: true
        })
    }

    componentWillUnmount() {
        if (interval) {
            _promise();
            clearInterval(interval);
        }
    }


    render() {
        const { definitionItem, breatStatus: { countDown } } = this.props;
        return (
            <div className="content-definicion">
                <h2>{definitionItem.title}</h2>
                <div className="main-definicion definicion-breath">

                    <div className="content-left">
                        <div className="definition-description-content">
                            <p>{definitionItem.description}</p>
                            {definitionItem.note &&
                                <p>{definitionItem.note}</p>
                            }
                        </div>

                        <div className="definition-steps-content">
                            {Object.assign([], definitionItem.steps).filter(item => item.title).map((item, indx) => (
                                <div key={`definition-step-item-${indx}`} className="definition-step-item">
                                    <h4>{item.title}</h4>
                                    {item.description && <p>{item.description}</p>}
                                </div>
                            ))}
                        </div>

                        {(this.state.context.id && !this.state.doit &&
                            (!definitionItem.currentBreathing || (definitionItem.currentBreathing && this.state.initBreathProscess))) &&
                            <div className="content-callback">
                                <span onClick={this.setCurrentContextCategory} className="callback">Hecho</span>
                            </div>
                        }

                    </div>


                    <div className="content-rigth">

                        <div className="content-breath-count">

                            <BreathIndicator doit={this.state.doit} definitionItem={definitionItem} limitTime={this.state.configBreathDefaultTime}
                                initBreathProscess={this.state.initBreathProscess} />

                            <span>
                                
                                {countDown}
                                <small>Seg</small>

                                <select ref={this.timeSelect} onChange={(e) => this.changeTimingBreath(e.currentTarget.value)}>
                                    <option value={60}>60 Seg</option>
                                    <option value={90}>90 Seg</option>
                                    <option value={120}>2 Min</option>
                                    <option value={180}>3 Min</option>
                                    <option value={240}>4 Min</option>
                                </select>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    definitionItem: state.definition.definitionItem,
    contexts: state.feedback.contexts,
    configBreathDefault: Object.assign({}, state.utils.config.breath),
    breatStatus: state.breathTool.breatStatus
});
const mapDispatchToProps = dispatch => (
    bindActionCreators({
        unSetDefinitionItem,
        setCurrentContextCategoryFeedback,
        putTimeBreathing,
        breathActionStop,
        setBreathTiming
    }, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(DefinitionBreath);
