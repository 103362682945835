import { all, fork } from 'redux-saga/effects'

import { logInSaga, getActorSaga, getConfigSaga,
    putNewTokenNotificationSaga, getCurrentTokenNotificationSaga
} from './states'
import {
    getQuestionsSaga, setAnswersSaga, getAnhelosSaga, getAnswersSaga, getAllAnswersSaga,
    getAnheloBeneficiosSaga, getAllEneaQuestiosSaga, setEneaActorSaga, setAsertActorSaga,
    getAllEneaBeneficiosSaga, setInterstActorSaga, getInterstActorSaga, updateInitInfoActorSaga,
    getAllCountInterestedActorSaga, getAllInterestedActorSaga, validateFormQuetionsSaga
} from './questions'

import {
    getContextFeedbackSaga, setFeedbackContextActorSaga,
    getFrecuentFeedbackCategoriesSaga
} from './feedback'
import { getSaturationPointNotificationSaga, putSaturationPointNotificationSaga } from './saturation'

import {
    getToolsSaga, putTimeBreathingSaga, syncRequestUserToolsSagaSaga, putNewProyectSaga,
    deleteProyectSaga, putNewTaskSaga, deleteTaskSaga
} from './tools'

import {
    breathActionSaga, stopBreathChanelSaga
} from './breath'



export default function* rootSaga() {
    yield all([
        fork(logInSaga),
        fork(getQuestionsSaga),
        fork(setAnswersSaga),
        fork(getAnhelosSaga),
        fork(getAnswersSaga),
        fork(getAllAnswersSaga),
        fork(getAnheloBeneficiosSaga),
        fork(getAllEneaQuestiosSaga),
        fork(setEneaActorSaga),
        fork(getActorSaga),
        fork(setAsertActorSaga),
        fork(getAllEneaBeneficiosSaga),
        fork(setInterstActorSaga),
        fork(getInterstActorSaga),
        fork(updateInitInfoActorSaga),
        fork(getAllCountInterestedActorSaga),
        fork(getAllInterestedActorSaga),
        fork(getContextFeedbackSaga),
        fork(setFeedbackContextActorSaga),
        fork(getFrecuentFeedbackCategoriesSaga),
        fork(getSaturationPointNotificationSaga),
        fork(putSaturationPointNotificationSaga),
        fork(getToolsSaga),
        fork(getConfigSaga),
        fork(putTimeBreathingSaga),
        fork(syncRequestUserToolsSagaSaga), // soket
        fork(putNewProyectSaga),
        fork(deleteProyectSaga),
        fork(putNewTaskSaga),
        fork(deleteTaskSaga),
        fork(putNewTokenNotificationSaga),
        fork(getCurrentTokenNotificationSaga),
        fork(validateFormQuetionsSaga),
        fork(breathActionSaga),
        fork(stopBreathChanelSaga)
    ]);
};
