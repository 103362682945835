import {
  SET_QUESTIONS_SAGA, SET_QUESTIONS_REDUCE, GET_QUESTIONS_SERVICE,
  SAVE_ANSWERS_SAGA, SAVE_ANSWERS_SERVICE, SET_ANSWERS_REDUCE,

  GET_ANHELOS_SERVICES, GET_ANHELOS_REDUCE, GET_ANHELOS_SAGA,
  GET_ANSWERS_SERVICE, GET_ANSWERS_SAGA,
  GET_ALL_ANSWERS_SERVICE, GET_ALL_ANSWERS_SAGA, SET_ALL_ANSWERS_REDUCER,
  GET_ANHELOS_BENEFICIOS_SERVICE, GET_ANHELOS_BENEFICIOS_SAGA, SET_ANHELOS_BENEFICIOS_REDUCER,
  GET_ALL_ENEA_QUESTIOS_SERVICE, GET_ALL_ENEA_QUESTIOS_SAGA, SET_ALL_ENEA_QUESTIOS_REDUCER,
  SET_ENEA_ACTOR_SERVICE, SET_ENEA_ACTOR_SAGA,
  GET_ACTOR_SAGA,
  SET_ASERT_ACTOR_SERVICE, SET_ASERT_ACTOR_SAGA,
  GET_ALL_ENEA_BENEFICIOS_SERVICE, GET_ALL_ENEA_BENEFICIOS_SAGA, SET_ENEA_BENEFICIOS_REDUCER,
  SAVE_INTEREST_ACTOR_SERVICE, SAVE_INTEREST_ACTOR_SAGA, SET_INTEREST_ACTOR_REDUCE,
  GET_INTEREST_ACTOR_SAGA, GET_INTEREST_ACTOR_SERVICE,
  UPDATE_INIT_INFO_ACTOR_SERVICE, UPDATE_INIT_INFO_ACTOR_SAGA,
  GET_ALL_COUNT_INTERSTEDS_ACTORS_SERVICE, SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA, SET_ALL_COUNT_INTERSTEDS_ACTORS_REDUCER,
  GET_ALL_INTERSTEDS_ACTORS_SERVICE, SET_ALL_INTERSTEDS_ACTORS_SAGA, SET_ALL_INTERSTEDS_ACTORS_REDUCER,
  SET_ALL_ACTORS_COMPANY_REDUCER,
  SET_VALIDATE_FORM_QUESTIONS_REDUCER,
  VALIDATE_FORM_QUESTIONS_SAGA
} from '../constantsGlobal'

import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'

import axios from 'axios'
// import { deprecate } from 'util';


function* getQuestions({ payload }) {
  const questios = yield call(axios.post, GET_QUESTIONS_SERVICE, {
    typePergunta: payload
  });
  // .filter(item => item.typeCampo === 1)
  yield put({
    type: SET_QUESTIONS_REDUCE, payload: questios.data
  });
}

function* setAnswers({ payload }) {
  
  yield call(axios.post, SAVE_ANSWERS_SERVICE, {
    answers: payload
  });

  yield put({
    type: SET_ANSWERS_REDUCE, payload: payload
  });
}

function* setInterstActor({ payload }) {
   yield call(axios.post, SAVE_INTEREST_ACTOR_SERVICE, {
    idActor: payload.idActor,
    interests: payload.interests
  });

  yield put({
    type: GET_INTEREST_ACTOR_SAGA, payload: payload.idActor
  });
}

function* getInterstActor({ payload }) {
  const interests = yield call(axios.post, GET_INTEREST_ACTOR_SERVICE, {
    idActor: payload
  });

  yield put({
    type: SET_INTEREST_ACTOR_REDUCE, payload: interests
  });
}


function* getAllAnswers({ payload }) {
  const questios = yield call(axios.post, GET_ALL_ANSWERS_SERVICE, {
    idCompany: payload
  });

  yield put({
    type: SET_ALL_ANSWERS_REDUCER, payload: questios.data
  });

  let actors = Object.assign([], questios.data).reduce((a, b) => {
    a[b.idActor] = b.p_actor
    return a;
  }, {})


  yield put({
    type: SET_ALL_ACTORS_COMPANY_REDUCER, payload: actors
  });

}


function* getAnhelos({ payload }) {
  const questios = yield call(axios.post, GET_ANHELOS_SERVICES);

  yield put({
    type: GET_ANHELOS_REDUCE, payload: questios.data
  });
}


function* getAnheloBeneficios({ payload }) {
  const questios = yield call(axios.post, GET_ANHELOS_BENEFICIOS_SERVICE);

  yield put({
    type: SET_ANHELOS_BENEFICIOS_REDUCER, payload: questios.data
  });
}


function* getAnswers({ payload }) {
  const questios = yield call(axios.post, GET_ANSWERS_SERVICE, {
    idActor: payload
  });

  yield put({
    type: SET_ANSWERS_REDUCE, payload: questios.data
  });
}

function* getAllEneaQuestios({ payload }) {
  const questios = yield call(axios.post, GET_ALL_ENEA_QUESTIOS_SERVICE);

  yield put({
    type: SET_ALL_ENEA_QUESTIOS_REDUCER, payload: questios.data
  });
}


function* getAllEneaBeneficios({ payload }) {
  const questios = yield call(axios.post, GET_ALL_ENEA_BENEFICIOS_SERVICE);

  yield put({
    type: SET_ENEA_BENEFICIOS_REDUCER, payload: questios.data
  });
}


function* setEneaActor({ payload }) {
  yield call(axios.post, SET_ENEA_ACTOR_SERVICE, {
    enea: payload.enea,
    actorId: payload.actorId
  });

  yield put({
    type: GET_ACTOR_SAGA, payload: payload.code
  });
}


function* setAsertActor({ payload }) {
  yield call(axios.post, SET_ASERT_ACTOR_SERVICE, {
    asert: payload.acert,
    actorId: payload.actorId
  });

  yield put({
    type: GET_ACTOR_SAGA, payload: payload.code
  });

}

function* updateInitInfoActor({ payload }) {
  yield call(axios.post, UPDATE_INIT_INFO_ACTOR_SERVICE, {
    enea: payload.enea,
    avatar: payload.avatar,
    genero: payload.genero,
    huella: payload.huella,
    actorId: payload.actorId,
  });

  yield put({
    type: GET_ACTOR_SAGA, payload: payload.code
  });

}


function* getAllCountInterestedActor({ payload }) {
  const interested = yield call(axios.post, GET_ALL_COUNT_INTERSTEDS_ACTORS_SERVICE, {
    empresa: payload
  });

  yield put({
    type: SET_ALL_COUNT_INTERSTEDS_ACTORS_REDUCER, payload: interested.data
  });

}


function* getAllInterestedActor({ payload }) {
  const interested = yield call(axios.post, GET_ALL_INTERSTEDS_ACTORS_SERVICE, {
    empresa: payload
  });

  yield put({
    type: SET_ALL_INTERSTEDS_ACTORS_REDUCER, payload: interested.data
  });

}


function* validateFormQuetions({ payload }) {

  const { questios } = yield select(state => state.diagnosis);

  let hallowNext = true;
  // debugger;
  switch (payload.stept) {
    case 2:
      hallowNext = !!payload.genero;
      break;
    case 3:
      hallowNext = !!payload.huella;
      break;
    case 4:
      hallowNext = !!payload.avatar;
      break;
    case 5:
      let _allow = questios.filter(item => item.typeCampo !== 7).reduce((a, b) => {
        return a * (payload.answers[String(b.id)] && payload.answers[String(b.id)] !== "" ? 1 : 0)
      }, 1);

      hallowNext = _allow === 1;
      break;
    case 6:
      hallowNext = payload.interestAct.length > 0;
      break;
    case 7:
      hallowNext = payload.interestInt.length > 0;
      break;
    default:
      break;
  }



  yield put({
    type: SET_VALIDATE_FORM_QUESTIONS_REDUCER, payload: !!hallowNext
  });
}



export function* validateFormQuetionsSaga() {
  yield takeEvery(VALIDATE_FORM_QUESTIONS_SAGA, validateFormQuetions);
}




export function* getQuestionsSaga() {
  yield takeEvery(SET_QUESTIONS_SAGA, getQuestions);
}

export function* setAnswersSaga() {
  yield takeLatest(SAVE_ANSWERS_SAGA, setAnswers);
}

export function* getAnhelosSaga() {
  yield takeEvery(GET_ANHELOS_SAGA, getAnhelos);
}

export function* getAnswersSaga() {
  yield takeEvery(GET_ANSWERS_SAGA, getAnswers);
}

export function* getAllAnswersSaga() {
  yield takeEvery(GET_ALL_ANSWERS_SAGA, getAllAnswers);
}

export function* getAnheloBeneficiosSaga() {
  yield takeEvery(GET_ANHELOS_BENEFICIOS_SAGA, getAnheloBeneficios);
}

export function* getAllEneaQuestiosSaga() {
  yield takeEvery(GET_ALL_ENEA_QUESTIOS_SAGA, getAllEneaQuestios);
}

export function* setEneaActorSaga() {
  yield takeEvery(SET_ENEA_ACTOR_SAGA, setEneaActor);
}


export function* setAsertActorSaga() {
  yield takeEvery(SET_ASERT_ACTOR_SAGA, setAsertActor);
}

export function* getAllEneaBeneficiosSaga() {
  yield takeEvery(GET_ALL_ENEA_BENEFICIOS_SAGA, getAllEneaBeneficios);
}

export function* setInterstActorSaga() {
  yield takeLatest(SAVE_INTEREST_ACTOR_SAGA, setInterstActor);
}

export function* getInterstActorSaga() {
  yield takeLatest(GET_INTEREST_ACTOR_SAGA, getInterstActor);
}

export function* updateInitInfoActorSaga() {
  yield takeLatest(UPDATE_INIT_INFO_ACTOR_SAGA, updateInitInfoActor);
}

export function* getAllCountInterestedActorSaga() {
  yield takeLatest(SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA, getAllCountInterestedActor);
}

export function* getAllInterestedActorSaga() {
  yield takeLatest(SET_ALL_INTERSTEDS_ACTORS_SAGA, getAllInterestedActor);
}

