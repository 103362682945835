import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginAction } from './actions/actions'
import { HOST_APP } from "./constantsGlobal"
import Redirect from './utils/redirect'
// import FA from 'react-fontawesome';

import logo_white from './resources/img/logo_white.png'


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            unactive: true
        }
        this.logIn = this.logIn.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    // componentDidMount(){
    //     const { loginAction } = this.props;
    //     loginAction({
    //         code: "avispa"
    //     })
    // }

    logIn() {
        const { loginAction } = this.props;
        loginAction({
            code: this.state.codigo
        })
    }

    redirect() {
        const { history } = this.props;
        history.push('/home');
    }


    render() {
        const { isLogin, history } = this.props;

        return (
            <section className="content-login">

                <div className="form-content">
                    <img alt="icon" src={logo_white} style={{ height: "14vmin", marginBottom: "3rem", minHeight: "50px" }} />
                    <div>
                        <div className={`form-section ${this.state.unactive ? 'unactive' : ''}`}>
                            {/* <h2>Código</h2>
                            <FA
                                className="incon-hint"
                                name="circle-o"
                            /> */}
                            <input
                                onFocus={() => this.setState({
                                    unactive: false
                                })}
                                onBlur={() => this.setState({
                                    unactive: !this.state.codigo
                                })}
                                onChange={(ev, val) => {
                                    this.setState({
                                        codigo: ev.currentTarget.value,
                                        unactive: !ev.currentTarget.value
                                    })
                                }} >
                            </input>
                        </div>

                        <button style={{
                            backgroundImage: `url(${HOST_APP}/bottom_bck.png)`
                        }}

                            className="button-primary" onClick={this.logIn}>Ingresar</button>
                    </div>
                </div>

                {/* <div className="brand-content" style={{
                    backgroundImage: `url(${HOST_APP}/IMG_001-min.png)`
                }}>
                    <div style={{ backgroundImage: `url(${HOST_APP}/degradado_trans_bottom.jpg)` }}>
                        <img alt="brand resource" src={`${HOST_APP}/BIOTICA-WHITE.png`} />
                    </div>

                </div> */}

                {isLogin && <Redirect currentHistory={history} route='/home/questions/' />}

            </section>
        );
    }
}


const mapStateToProps = state => ({
    isLogin: state.diagnosis.isLogin,
    actor: state.diagnosis.actor
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loginAction
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);
