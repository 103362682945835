import React, { Component } from 'react';
import { connect } from 'react-redux';

class AtomicQuestion extends Component {
    

    componentWillMount() {
        const { onLoadComponent } = this.props;

        if (onLoadComponent) {
            onLoadComponent()
        }

    }

    render() {
        const { questio, options, gridView, className } = this.props;

        return (
            <div className={`item-center atomic-quest ${className}`} >
                <h2>{questio}</h2>
                {!gridView &&
                    <div className={`grid-content single`}>
                        {options}
                    </div>
                }


                {gridView &&
                    <div className={`grid-content ${(options.length % 2) === 1 ? "qsquare" : "square"}`}>
                        {options}
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(AtomicQuestion);
