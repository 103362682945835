import {
    SET_TOOLS_REDUCER, GET_TOOLS_SERVICE, GET_TOOLS_SAGA,
    USER_TOOLS_SERVICE, PUT_TIME_BREATHING_SAGA, SET_USER_TOOLS_STATE_SAGA, INIT_WATCH_TOOLS_SAGA,
    PUT_NEW_PROYECT_SAGA, DELETE_PROYECT_SAGA, PUT_NEW_TASKS_SAGA, DELETE_TASK_SAGA
} from '../constantsGlobal'

import { call, put, takeLatest, fork, select } from 'redux-saga/effects'


import firebase from "firebase";

import {rsf} from '../utils/config'




function* getTools() {

    const tools = yield rsf.database.read(GET_TOOLS_SERVICE);

    yield put({
        type: SET_TOOLS_REDUCER, payload: tools
    });

}



function* putTimeBreathing({ payload }) {
    const { actor } = yield select(state => state.diagnosis)
    const { actorTools } = yield select(state => state.utils)
    yield call(rsf.database.patch, `${USER_TOOLS_SERVICE}/${actor.id}`,
        { timeBreathing: actorTools.timeBreathing ? actorTools.timeBreathing + payload : payload }
    );

}

function* putNewProyect({ payload }) {
    const { actor } = yield select(state => state.diagnosis)

    yield call(rsf.database.create, `${USER_TOOLS_SERVICE}/${actor.id}/proyects`,
        {
            creationDate: firebase.database.ServerValue.TIMESTAMP,
            name: payload,
            tasks: {}
        }
    );
}

function* putNewTask({ payload }) {
    const { actor } = yield select(state => state.diagnosis)
    const { currentDateScheduler } = yield select(state => state.utils)

    yield call(rsf.database.create, `${USER_TOOLS_SERVICE}/${actor.id}/scheduler/${currentDateScheduler}`,
    {
        creationDate: firebase.database.ServerValue.TIMESTAMP,
        "from":payload.from,
        "to":payload.to,
        "task":payload.task,
        "type":payload.type
    });
    

}

function* deleteProyect({ payload }) {
    const { actor } = yield select(state => state.diagnosis)
    yield call(rsf.database.delete, `${USER_TOOLS_SERVICE}/${actor.id}/proyects/${payload}`);
}

function* deleteTask({ payload }) {
    const { actor } = yield select(state => state.diagnosis)
    const { currentDateScheduler } = yield select(state => state.utils)
    yield call(rsf.database.delete, `${USER_TOOLS_SERVICE}/${actor.id}/scheduler/${currentDateScheduler}/${payload.taskId}`);
}




export function* getToolsSaga() {
    yield takeLatest(GET_TOOLS_SAGA, getTools);
}

export function* putTimeBreathingSaga() {
    yield takeLatest(PUT_TIME_BREATHING_SAGA, putTimeBreathing);
}

export function* putNewProyectSaga() {
    yield takeLatest(PUT_NEW_PROYECT_SAGA, putNewProyect);
}

export function* putNewTaskSaga() {
    yield takeLatest(PUT_NEW_TASKS_SAGA, putNewTask);
}

export function* deleteProyectSaga() {
    yield takeLatest(DELETE_PROYECT_SAGA, deleteProyect);
}

export function* deleteTaskSaga() {
    yield takeLatest(DELETE_TASK_SAGA, deleteTask);
}





// function* syncRequestFeedbackSaga() {
//     const { actor } = yield select(state => state.diagnosis.actor)

//     // console.log(`${PUT_RESQUEST_FEEDBACK_SERVICE}${currentUser.empresa}`)
//     yield fork(rsf.database.sync, `${USER_TOOLS_SERVICE}${actor.id}`, {
//         successActionCreator: (payload) => ({
//             type: SET_RESQUEST_FEEDBACK_FEEDBACK_REDUCER, payload
//         })
//     })
// }


// export function* initWatchRequestFeedbackSaga() {
//     yield takeLatest(INIT_WATCH_RESQUEST_FEEDBACK_FEEDBACK_SAGA, syncRequestFeedbackSaga);
// }

function* syncRequestUserToolsSaga() {
    const { actor } = yield select(state => state.diagnosis)

    yield fork(rsf.database.sync, `${USER_TOOLS_SERVICE}/${actor.id}`, {
        successActionCreator: (payload) => ({
            type: SET_USER_TOOLS_STATE_SAGA, payload
        })
    })
}


export function* syncRequestUserToolsSagaSaga() {
    yield takeLatest(INIT_WATCH_TOOLS_SAGA, syncRequestUserToolsSaga);
}