import {
    GET_CONTEXT_FEEDBACK_SERVICE, GET_CONTEXT_FEEDBACK_SAGA, SET_CONTEXT_FEEDBACK_REDUCER,
    SET_FEEDBACK_CONTEXT_ACTOR_SERVICE, SET_FEEDBACK_CONTEXT_ACTOR_SAGA, SET_ALERT,
    GET_FRECUENT_FEEDBACK_CATEGORIES_SERVICE, GET_FRECUENT_FEEDBACK_CATEGORIES_SAGA, GET_FRECUENT_FEEDBACK_CATEGORIES_REDUCER
} from '../constantsGlobal'

import { call, put, takeLatest } from 'redux-saga/effects'

import axios from 'axios'
// import { deprecate } from 'util';


function* getContextFeedback({ payload }) {
    const feedbackContext = yield call(axios.post, GET_CONTEXT_FEEDBACK_SERVICE, {
        idTeam: payload
    });

    yield put({
        type: SET_CONTEXT_FEEDBACK_REDUCER, payload: feedbackContext.data
    });

}


function* setFeedbackContextActor({ payload }) {
    yield call(axios.post, SET_FEEDBACK_CONTEXT_ACTOR_SERVICE, {
        idActor: payload.idActor,
        idContext: payload.idContext,
        idCategoria: payload.idCategoria,
        valor: payload.valor,
        detalle: payload.detalle
    });

    yield put({
        type: SET_ALERT, payload: {
            message: 'Mensaje enviado',
            time: 3000,
            alertType: 'info'
        }
    });

}



function* getFrecuentFeedbackCategories({ payload }) {
    const frecuentFeedbackCategories = yield call(axios.post, GET_FRECUENT_FEEDBACK_CATEGORIES_SERVICE, {
        idCompany: payload.idCompany,
        idActor: payload.idActor
    });

    yield put({
        type: GET_FRECUENT_FEEDBACK_CATEGORIES_REDUCER, payload: frecuentFeedbackCategories.data
    });
}



export function* getContextFeedbackSaga() {
    yield takeLatest(GET_CONTEXT_FEEDBACK_SAGA, getContextFeedback);
}

export function* setFeedbackContextActorSaga() {
    yield takeLatest(SET_FEEDBACK_CONTEXT_ACTOR_SAGA, setFeedbackContextActor);
}

export function* getFrecuentFeedbackCategoriesSaga() {
    yield takeLatest(GET_FRECUENT_FEEDBACK_CATEGORIES_SAGA, getFrecuentFeedbackCategories);
}

