import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Question from './questions/question';
import QuestiosThumbContent from './questions/questiosThumbContent'
import QuestThumb from './questions/questThumb';

import {
    saveAnswers, setInterestActor, updateInitInfoActor, setEneaActor, setInfoFooter, enableFooterNextValue, disableFooterNextValue,
    setFooterValue, validateFormQuetions
} from '../actions/actions';
import Redirect from '../utils/redirect';


import AtomicQuestion from './questions/atomicQuestion'

import { questionsDesatend, HOST_APP, getAnheloPersona, anhelosdef } from '../constantsGlobal'

import FA from 'react-fontawesome';


class QuestionsContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: {},

            interestAct: [],
            interestInt: [],
            stept: 1,
            messajes: {
                "1": "Ayúdanos respondiendo la siguiente encuesta.",
                "2": "Queremos conocerte un poco más, cuéntanos qué actividades llaman tu atención.",
                "3": "Genial, ya casi terminamos!, selecciona las actividades que realizas actualmente."
            },
            isLevelBreadBase: true,
            hallowNext: false,
            showModal: true,
            genero: "",
            huella: "",
            avatar: "",
            enea: "",
            temores: []
        };

        this.setAnswers = this.setAnswers.bind(this);
        this.saveAnswers = this.saveAnswers.bind(this);
        this.setInterestAct = this.setInterestAct.bind(this);
        this.setInterestInt = this.setInterestInt.bind(this);

        this.selectTemor = this.selectTemor.bind(this);
        this.getAnhelo = this.getAnhelo.bind(this);
        this.nextStept = this.nextStept.bind(this);

    }

    componentWillMount() {
        const { actor, history, setInfoFooter } = this.props;

        if (!actor.id) {
            history.push("/");
        }

        setInfoFooter({
            type: "steeps",
            childs: [
                { name: "Atras", value: "prev", getVisibility: (step) => (step > 1) },
                { name: "Siguiente", value: "next", getVisibility: (step) => (step < 8) },
                { name: "Guardar", value: "save", getVisibility: (step) => (step === 8) }
            ],
            value: 1
        })




    }

    saveAnswers() {
        const { saveAnswers, actor, questios, setInterestActor, updateInitInfoActor } = this.props;

        let typesCampos = questios.reduce((a, b) => {
            a[b.id] = b.typeCampo;
            return a;
        }, {});

        let _answers = Object.keys(this.state.answers)
            .map((idQuestion) => {
                let isValue = !(typesCampos[idQuestion] === 1 || typesCampos[idQuestion] === 2);
                return {
                    respuesta: this.state.answers[idQuestion],
                    idRespuesta: !isValue ? this.state.answers[idQuestion] : null,
                    idPregunta: idQuestion,
                    idActor: actor.id
                }
            })

        saveAnswers(_answers);

        let _interests = [];


        this.state.interestAct.forEach((interest) => {
            _interests.push({
                idActor: actor.id,
                interest: interest.interest,
                priority: 1,
                route: interest.route
            })
        });
        this.state.interestInt.forEach((interest) => {
            _interests.push({
                idActor: actor.id,
                interest: interest.interest,
                priority: 0,
                route: interest.route
            })
        });

        if (_interests.length > 0)
            setInterestActor({
                idActor: actor.id,
                interests: _interests
            });

        updateInitInfoActor({
            enea: this.state.enea,
            avatar: this.state.avatar,
            genero: this.state.genero,
            huella: this.state.huella,
            actorId: actor.id,
            code: actor.codigo
        })


    }

    setAnswers(idAnswer, idQuestion) {
        const { validateFormQuetions } = this.props;
        let answers = Object.assign({}, this.state.answers);
        answers[idQuestion] = idAnswer;



        this.setState({
            answers: answers
        });

        validateFormQuetions({
            ...this.state,
            answers: answers
        });

    }

    setInterestAct(inte) {
        const { validateFormQuetions } = this.props;

        this.setState({
            interestAct: inte
        });
        validateFormQuetions({
            ...this.state,
            interestAct: inte
        });

    }

    setInterestInt(inte) {
        const { validateFormQuetions } = this.props;

        this.setState({
            interestInt: inte
        });
        validateFormQuetions({
            ...this.state,
            interestInt: inte
        });

    }


    openModal(componente) {
        this.setState({
            showModal: true,
            currentModalComponent: componente
        })
    }

    setInfo(key, value) {
        const { validateFormQuetions } = this.props;

        let _value = {};
        _value[key] = value;
        this.setState(_value)

        let dataStorage = {
            enea: this.state.enea,
            avatar: this.state.avatar,
            genero: this.state.genero,
            huella: this.state.huella,
        }
        dataStorage[key] = value;


        validateFormQuetions({
            ...this.state,
            ..._value
        });

    }

    selectTemor(eneatipo) {

        this.setState({
            enea: eneatipo
        })
    }

    getAnhelo() {
        const { anhelos, eneaQuestios } = this.props;

        let _answersRaw = Object.keys(this.state.answers).map(idQuestion => ({ idRespuesta: this.state.answers[idQuestion] }));

        let consolidate = getAnheloPersona(_answersRaw, anhelos);

        let _anheloPred = consolidate.predominante.anhelo;

        let temores = eneaQuestios.filter(item => !!anhelosdef[_anheloPred] && item.anhelonumber === anhelosdef[_anheloPred].anhelonumber);
        this.setState({
            temores
        })
    }


    nextStept(newStept) {
        const { validateFormQuetions, validFormQuetions } = this.props;
        if (validFormQuetions) {

            validateFormQuetions({
                ...this.state,
                stept: newStept
            });

            this.setState({
                stept: newStept
            })

        }
    }





    render() {
        const { actor, questios, history, answers, validFormQuetions } = this.props;

        let stept = this.state.stept;

        let _steptSequence = 1;
        return (
            <section className={`section-quest 
            ${stept === 5 ? 'quest-itmes-grid' : ''}
            ${stept === 7 ? 'content-thumbs-section' : ''}

            `} >

                {stept === (_steptSequence++) &&
                    <div className="welcolme-content">
                        <h1>Hola {actor.nombre}</h1>
                        <p>{this.state.messajes[stept]}</p>
                    </div>
                }

                {stept === (_steptSequence++) &&

                    <AtomicQuestion
                        gridView={true}
                        questio={questionsDesatend.genero.title}
                        options={questionsDesatend.genero.options.map(option => (
                            <div className={this.state.genero === option.value ? "atomic-quest-selected" : ""}
                                onClick={() => this.setInfo("genero", option.value)}>
                                <img alt="genero" style={{ background: "transparent" }} src={`${HOST_APP}${option.img}`} />
                                <span>{option.name}</span>
                            </div>
                        ))}
                    />
                }

                {stept === (_steptSequence++) &&

                    <AtomicQuestion
                        gridView={true}
                        questio={`La huella de tu indice ${this.state.genero === "M" ? "izquierdo" : "derecho"} se parece a`}
                        options={questionsDesatend.fingerPrint.options.map(option => (
                            <img alt="huella" className={this.state.huella === option.value ? "atomic-quest-selected" : ""}
                                onClick={() => this.setInfo("huella", option.value)} src={`${HOST_APP}${option.img}`} />
                        ))}
                    />
                }

                {stept === (_steptSequence++) &&

                    <AtomicQuestion
                        gridView={true}
                        questio={questionsDesatend.avatar.title}
                        options={questionsDesatend.avatar.options.map(option => (
                            <img alt="avatar" className={this.state.avatar === option.value ? "atomic-quest-selected" : ""}
                                onClick={() => this.setInfo("avatar", option.value)} src={`${HOST_APP}${option.img}`} />
                        ))}
                    />
                }

                {stept === (_steptSequence++) &&
                    questios.map(quest => <Question answers={this.state.answers} setAnser={(idAnswer) => this.setAnswers(idAnswer, quest.id)} question={quest} />)
                    // <div className="content-questions">
                    //     {
                    //     }
                    // </div>
                }

                {stept === (_steptSequence++) &&
                    <QuestiosThumbContent deepLevelBread={(breadPick) => this.setState({
                        isLevelBreadBase: breadPick.length === 0
                    })} setInterest={(interest) => this.setInterestAct(interest)} />
                }

                {stept === (_steptSequence++) &&
                    <QuestThumb setInterest={(interest) => this.setInterestInt(interest)} interests={this.state.interestAct} />
                }

                {stept === (_steptSequence) &&
                    <AtomicQuestion
                        onLoadComponent={() => this.getAnhelo()}
                        questio={questionsDesatend.fears.title}
                        options={this.state.temores.map((items) => (
                            <span className={this.state.enea === items.enatipo ? "active" : ""} onClick={() => this.selectTemor(items.enatipo)} >{items.pregunta}</span>
                        ))}
                    />
                }


                {stept < _steptSequence && this.state.isLevelBreadBase &&
                    <button className={validFormQuetions ? "" : "disable"} onClick={() => {
                        this.nextStept(++stept)
                    }} >Continuar</button>
                }

                {(stept === _steptSequence && validFormQuetions && this.state.isLevelBreadBase) &&
                    <button onClick={this.saveAnswers} style={{ color: "white" }} >Guardar</button>
                }

                {(stept === (_steptSequence + 1)) && <div className="welcolme-content">
                    <FA
                        className="spin-question"
                        name="spinner"
                        size="2x"
                        spin
                        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', color: "white" }}
                    />
                </div>}



                {answers && answers.length > 0 && <Redirect currentHistory={history} route={'/home/profile/'} />}


                {(stept === 5 || stept === 7 )  && <span style={{ height: "1px" }}></span>}

            </section>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers,
    anhelos: state.diagnosis.anhelos,
    eneaQuestios: state.diagnosis.eneaQuestios,
    steep: state.footer.value,
    validFormQuetions: state.utils.validFormQuetions,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        saveAnswers,
        setInterestActor,
        updateInitInfoActor,
        setEneaActor,
        setInfoFooter,
        enableFooterNextValue,
        disableFooterNextValue,
        setFooterValue,
        validateFormQuetions
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(QuestionsContent);
