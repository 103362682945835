import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment'

class DayOfWeekItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numActividades: 0,
            totalTime: "0 min"
        }
        this.buildDayOfWeekItem = this.buildDayOfWeekItem.bind(this);
    }

    componentDidMount() {
        const { actorTools } = this.props;
        let scheduler = actorTools ? Object.assign({}, actorTools.scheduler) : {};
        this.buildDayOfWeekItem(scheduler);
    }

    buildDayOfWeekItem(scheduler) {
        const { day } = this.props;
        let currDateItem = String(moment(day).format()).substring(0, 10)

        let _fromDate = new Date();
        let _toDate = new Date();

        let currTasksObj = Object.assign({}, scheduler[currDateItem]);
        let sumTime = Object.keys(currTasksObj).reduce((a, b) => {
            let _from = moment(`${currDateItem}T${currTasksObj[b].from}:00-05:00`);
            let _to = moment(`${currDateItem}T${currTasksObj[b].to}:00-05:00`);
            return a + _from.diff(_to)
        }, 0)

        _toDate = moment(_toDate).add(sumTime, 'milliseconds');
        let _sumTimeDif = moment(_fromDate).diff(_toDate, 'minutes');
        _sumTimeDif = _sumTimeDif > 120 ? moment(_fromDate).diff(_toDate, 'hours') + " Horas" : _sumTimeDif + ' Min';

        this.setState({
            numActividades: Object.keys(currTasksObj).length,
            totalTime: _sumTimeDif
        })
    }


    componentWillUpdate(next) {
        const { actorTools } = this.props;

        let _scheduler = actorTools ? Object.assign({}, actorTools.scheduler) : {};
        let _currTasksObj = Object.assign({}, _scheduler[next.currentDateScheduler]);
        let _currentTasksDay = Object.keys(_currTasksObj).length;

        let scheduler = next.actorTools ? Object.assign({}, next.actorTools.scheduler) : {};
        let currTasksObj = Object.assign({}, scheduler[next.currentDateScheduler]);
        let currentTasksDay = Object.keys(currTasksObj).length;

        if (_currentTasksDay !== currentTasksDay) {
            this.buildDayOfWeekItem(scheduler)
        }
    }



    render() {
        const { day, currentDateScheduler, onClick } = this.props
        return (
            <div className={`dow-item ${moment(day).isSame(currentDateScheduler, 'day') ? 'active' : ''}`}
                onClick={onClick}>
                <div>
                    <span>{moment(day).format("dddd")}</span>
                    <span>{moment(day).format("D")}</span>
                </div>
                <hr />
                <div>
                    <span>{this.state.numActividades} Actividades</span>
                    <span>Total tiempo: {this.state.totalTime}</span>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    actorTools: state.utils.actorTools,
    currentDateScheduler: state.utils.currentDateScheduler
});
const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(DayOfWeekItem);