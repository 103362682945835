import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'

// import { HOST_APP } from '../constantsGlobal'

import { logOut } from '../actions/actions'
import { bindActionCreators } from 'redux';


import logo_white from '../resources/img/logo_white_down.png'

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            description: {},
            showMenu: false
        }

    }

    render() {
        const { logOut } = this.props;
        const { answers } = this.props;

        return (
            <header className="top-nav">
                <div className="aside-left">
                    {/* <img src={`${HOST_APP}/BIOTICA-WHITE.png`} alt="logo" /> */}
                    <img src={logo_white} alt="logo" />
                </div>
                <div className="aside-rigth">
                    <div className="content-avatar" style={{ position: "relative" }}>
                        <i class="fa fa-bars" onClick={() => this.setState({
                            showMenu: !this.state.showMenu
                        })} ></i>

                        <div className={`colapse-menu ${this.state.showMenu ? "active" : ""}`}>
                            {answers.length > 0 && <>
                                <NavLink exact to={`/home/profile/`} activeClassName="active">Perfil</NavLink>
                                <NavLink exact to={`/home/breathingTools/`} activeClassName="active">Respiración</NavLink>
                            </>}
                            <NavLink exact to={`/`} activeClassName="active" onClick={logOut}>Cerrar sesion</NavLink>
                        </div>
                        {/* <span> Cerrar sesión </span> */}

                    </div>
                </div>
            </header >
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    answers: state.diagnosis.answers
});
const mapDispatchToProps = dispatch => bindActionCreators({
    logOut
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
