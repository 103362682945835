import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { imagesResources } from "../../resources/resources"

import {HOST_APP} from '../../constantsGlobal'

class QuestThumbEpyc extends Component {
    constructor(props) {
        super(props);
        this.selectAnswer = this.selectAnswer.bind(this);
        this.getImage = this.getImage.bind(this);
    }


    selectAnswer(valor) {
        const { setAnser } = this.props;
        setAnser(valor);
    }

    getImage(name) {
        return Object.keys(imagesResources).filter((image) => image === name).reduce((a, b) => {
            return imagesResources[b]
        }, "./images/dummy.jpg")
    }


    render() {
        const { name, setObject, checked, disable } = this.props;
        return (
            <div className={`quest-thumb-grid-item ${checked ? "check" : ""} ${disable ? "disable" : ""}`} onClick={() => setObject()} >
                <h3>{name}</h3>
                <div style={{ backgroundImage: `url('${HOST_APP}${this.getImage(name)}')` }} className="fade-back-thumb">
                    <span></span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(QuestThumbEpyc);
