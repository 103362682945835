import {
    LOGIN_SAGA, SAVE_ANSWERS_SAGA, GET_ALL_ANSWERS_SAGA, GET_ANHELOS_BENEFICIOS_SAGA,
    SET_ENEA_ACTOR_SAGA, SET_ASERT_ACTOR_SAGA,
    GET_ALL_ENEA_BENEFICIOS_SAGA,
    OPEN_MODAL_REDUCE, CLOSE_MODAL_REDUCE, SAVE_INTEREST_ACTOR_SAGA,
    UPDATE_INIT_INFO_ACTOR_SAGA, SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA, SET_ALL_INTERSTEDS_ACTORS_SAGA,
    UPDATE_FOOTER_REDUCER, UPDATE_FOOTER_VALUE_REDUCER,
    ENABLE_FOOTER_NEXT_REDUCER, DISABLE_FOOTER_NEXT_REDUCER,
    GET_CONTEXT_FEEDBACK_SAGA, SET_CURRENT_CONTEXT_CATEGORY_REDUCER, UNSET_CURRENT_CONTEXT_CATEGORY_REDUCER,
    SET_FEEDBACK_CONTEXT_ACTOR_SAGA, SET_ALERT, UNSET_ALERT,
    GET_FRECUENT_FEEDBACK_CATEGORIES_SAGA,
    SET_DEFINITION_ITEM, UNSET_DEFINITION_ITEM,
    PUT_SATURATION_POINT_NOTIFICATION_SAGA,
    PUT_TIME_BREATHING_SAGA,
    PUT_NEW_PROYECT_SAGA, DELETE_PROYECT_SAGA, PUT_NEW_TASKS_SAGA,
    DELETE_TASK_SAGA,
    SET_CURRENT_DATE_SCHEDULER, 
    VALIDATE_FORM_QUESTIONS_SAGA,
    LOGOUT_REDUCER
} from '../constantsGlobal'



export function openModal(idModal) {
    return { type: OPEN_MODAL_REDUCE, payload: idModal }
}

export function closeModal() {
    return { type: CLOSE_MODAL_REDUCE }
}



export function loginAction(credentials) {
    return { type: LOGIN_SAGA, payload: credentials }
}

export function saveAnswers(anwers) {
    return { type: SAVE_ANSWERS_SAGA, payload: anwers }
}

export function getAllAnwers(idCompany) {
    return { type: GET_ALL_ANSWERS_SAGA, payload: idCompany }
}

export function getAnhelosBeneficios() {
    return { type: GET_ANHELOS_BENEFICIOS_SAGA }
}

export function setEneaActor(payload) {
    return { type: SET_ENEA_ACTOR_SAGA, payload }
}

export function setAsertActor(payload) {
    return { type: SET_ASERT_ACTOR_SAGA, payload }
}

export function getAllEneaBeneficios() {
    return { type: GET_ALL_ENEA_BENEFICIOS_SAGA }
}


export function setInterestActor(payload) {
    return { type: SAVE_INTEREST_ACTOR_SAGA, payload }
}

export function updateInitInfoActor(payload) {
    return { type: UPDATE_INIT_INFO_ACTOR_SAGA, payload }
}

export function getAllCountInterestedActor(empresa) {
    return { type: SET_ALL_COUNT_INTERSTEDS_ACTORS_SAGA, payload: empresa }
}

export function getAllInterestedActor(empresa) {
    return { type: SET_ALL_INTERSTEDS_ACTORS_SAGA, payload: empresa }
}


export function setInfoFooter(info) {
    return { type: UPDATE_FOOTER_REDUCER, payload: info }
}

export function setFooterValue(value) {
    return { type: UPDATE_FOOTER_VALUE_REDUCER, payload: value }
}


export function enableFooterNextValue() {
    return { type: ENABLE_FOOTER_NEXT_REDUCER }
}

export function disableFooterNextValue() {
    return { type: DISABLE_FOOTER_NEXT_REDUCER }
}


export function getContextFeedback(idTeam) {
    return { type: GET_CONTEXT_FEEDBACK_SAGA, payload: idTeam }
}

export function setCurrentContextCategoryFeedback(current) {
    return { type: SET_CURRENT_CONTEXT_CATEGORY_REDUCER, payload: current }
}
export function unsetCurrentContextCategoryFeedback() {
    return { type: UNSET_CURRENT_CONTEXT_CATEGORY_REDUCER }
}

export function setFeedbackContextActor(feedback) {
    return { type: SET_FEEDBACK_CONTEXT_ACTOR_SAGA, payload: feedback }
}

export function setAlert(feedback) {
    return { type: SET_ALERT, payload: feedback }
}

export function unsetAlert() {
    return { type: UNSET_ALERT }
}


export function getFrecuentFeedbackCategories(payload) {
    return { type: GET_FRECUENT_FEEDBACK_CATEGORIES_SAGA, payload }
}

// definitions
export function setDefinitionItem(payload) {
    return { type: SET_DEFINITION_ITEM, payload }
}

export function unSetDefinitionItem() {
    return { type: UNSET_DEFINITION_ITEM }
}


export function putSaturationPointNotification(saturationPoints) {
    return { type: PUT_SATURATION_POINT_NOTIFICATION_SAGA, payload: saturationPoints }
}

// Tools
export function putTimeBreathing(time) {
    return { type: PUT_TIME_BREATHING_SAGA, payload: time }
}

export function putNewProyect(proyectName) {
    return { type: PUT_NEW_PROYECT_SAGA, payload: proyectName }
}
export function putNewTasks(payload) {
    return { type: PUT_NEW_TASKS_SAGA, payload: payload }
}

export function deleteProyect(proyectName) {
    return { type: DELETE_PROYECT_SAGA, payload: proyectName }
}


export function deleteTask(payload) {
    return { type: DELETE_TASK_SAGA, payload: payload }
}

export function setCurrentDateScheduller(payload) {
    return { type: SET_CURRENT_DATE_SCHEDULER, payload: payload }
}

export function validateFormQuetions(payload) {
    return { type: VALIDATE_FORM_QUESTIONS_SAGA, payload: payload }
}

export function logOut() {
    return { type: LOGOUT_REDUCER }
}

// breath

export function breathAction(payload) {
    return { type: "INIT_BREATHING", payload: payload }
}

export function breathActionStop() {
    return { type: "STOP_BREATHING" }
}

export function setBreathTiming(payload) {
    return { type: "PUT_BREATH_TIMING" , payload: payload }
}