import React, { Component } from 'react';

import { indPaths } from '../../constantsGlobal'

export default class BreathIndicatorPath extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: ""
        }
    }

    componentWillMount() {
        const {path} = this.props;
        this.setState({
            path: path
        })
    }

    componentDidMount() {
        setInterval(() => {
            let _indx = Math.trunc((Math.random() * 4) + 1);
            this.setState({
                path: indPaths[_indx]
            })
        }, 1000);
    }



    render() {
        return (
            <path fill="rgba(45,113,138, 0.3)" d={this.state.path} transform="translate(100 100)" />
        )
    }
}


