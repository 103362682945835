import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { accionesEnea, enumFeedbackContext } from '../constantsGlobal'

import { setDefinitionItem, getContextFeedback, setCurrentContextCategoryFeedback } from '../actions/actions'

import { definitions } from '../resources/resources'

import FA from 'react-fontawesome';

class Route extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consolidate: {},
            predominante: {},
            chartAnhelo: [],
            modalAsertOpen: false,
            showModal: false
        }

        this.selectFeedbackCallBack = this.selectFeedbackCallBack.bind(this);
        this.selectBreathCallBack = this.selectBreathCallBack.bind(this);
        this.selectSelfFeedbackCallBack = this.selectSelfFeedbackCallBack.bind(this);
    }



    componentWillMount() {

        const { answers, anhelos, actor, history, getContextFeedback, contexts } = this.props;

        if (!actor.id) {
            history.push("/");
        }

        if (contexts.length === 0) {
            getContextFeedback(Object.assign([], actor.p_team_actors).map(ta => ta.idTeam))
        }

        let consolidate = {};
        answers.filter(answ => answ.idRespuesta != null).forEach(answ => {
            let _anhelos = anhelos.find(anh => anh.idRespuesta === answ.idRespuesta);
            if (_anhelos) {
                [].concat(_anhelos).forEach(anh => {
                    consolidate[anh.anhelo] = consolidate[anh.anhelo] ? consolidate[anh.anhelo] + 1 : 1;
                });
            }
        });

        let predominante = Object.keys(consolidate)
            .map(item => ({ "anhelo": item, "number": consolidate[item] }))
            .reduce((a, b, i) => {
                return a.number < b.number ? b : a
            }, { number: -1 })

        let chartAnhelo = Object.keys(consolidate).map(item => {
            return {
                name: item,
                value: consolidate[item],
                fullMark: 15
            }
        })

        this.setState({
            consolidate: consolidate,
            predominante: predominante,
            chartAnhelo
        })

    }

    selectFeedbackCallBack() {
        const { setDefinitionItem, history } = this.props;
        setDefinitionItem({
            ...definitions.feedback,
            contextId: enumFeedbackContext.fdbkExt.id,
            categoryId: enumFeedbackContext.fdbkExt.category.feedback
        });
        history.push("/home/definition/");
    }

    selectSelfFeedbackCallBack() {

        const { setCurrentContextCategoryFeedback, history, contexts } = this.props;

        let ctxFeedBack = contexts.filter(context => context.id === enumFeedbackContext.fdbkMine.id)
            .map(context => {
                return {
                    ...context,
                    category: context.p_feedback_categories
                        .filter(category => category.id === enumFeedbackContext.fdbkMine.category.init).reduce((a, b) => b, {})
                }
            }).reduce((a, b) => b, {})


        setCurrentContextCategoryFeedback({
            context: ctxFeedBack, category: ctxFeedBack.category
        })

        history.push("/home/feedbackDetail/");

    }

    selectBreathCallBack() {
        const { history } = this.props;
        history.push("/home/breathingTools/");
    }


    render() {
        const { actor } = this.props;

        // // let temores = eneaQuestios.filter(item => !!anhelosdef[this.state.predominante.anhelo] && item.anhelonumber == anhelosdef[this.state.predominante.anhelo].anhelonumber);
        // let numeroAnhelo = this.state.predominante.anhelo && !!anhelosdef[this.state.predominante.anhelo] ? anhelosdef[this.state.predominante.anhelo].anhelonumber : 1;

        // // let _predominante = Object.assign(this.state.predominante)  ;
        // let _anhelosdef = Object.assign({}, anhelosdef[this.state.predominante.anhelo]);

        // let dataDonut = Object.keys(this.state.consolidate).map(item => ({
        //     anheloNombre: Object.assign({}, anhelosdef[item]).centro,
        //     value: this.state.consolidate[item]
        // }))

        return (
            <div className="content-route">
                <div >
                    <div className="content-full">
                        {/* <DonutChart data={dataDonut}/> */}
                        <div className="content-metris-route">

                            <div className="item-metris-route">
                                <div>
                                    <div>
                                        <h3>Comunicación</h3>
                                        <p>¿Consideras que tu feedback a otras personas es bueno?</p>
                                    </div>
                                    <FA name="commenting" />
                                </div>
                                <div onClick={this.selectFeedbackCallBack}>
                                    <span>Abrir</span>
                                </div>
                            </div>
                            <div className="item-metris-route">
                                <div>
                                    <div>
                                        <h3>Imagen de yo</h3>
                                        <p>¿Conoces tu potencial?</p>
                                    </div>
                                    <FA name="user-circle-o" />
                                </div>
                                <div onClick={this.selectSelfFeedbackCallBack}>
                                    <span>Abrir</span>
                                </div>
                            </div>
                            <div className="item-metris-route">
                                <div>
                                    <div>
                                        <h3>Respiracion</h3>
                                        <p>¿Sabes repirar?</p>
                                    </div>
                                    <FA name="heartbeat" />
                                </div>
                                <div onClick={this.selectBreathCallBack}>
                                    <span>Abrir</span>
                                </div>
                            </div>
                         

                        </div>
                    </div>
                    <div className="content-full">
                        <h2 className="underline" style={{ marginTop: '1rem' }}>Tips</h2>
                        <div className="content-vergical-targets">
                            {accionesEnea[actor.eneatipo] && accionesEnea[actor.eneatipo].map(item =>
                                <div className="item-power">
                                    <span>{item}</span>
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers,
    anhelos: state.diagnosis.anhelos,
    eneaQuestios: state.diagnosis.eneaQuestios,
    contexts: state.feedback.contexts
});
const mapDispatchToProps = dispatch => bindActionCreators({
    setDefinitionItem,
    getContextFeedback,
    setCurrentContextCategoryFeedback
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Route);
