import ReduxSagaFirebase from 'redux-saga-firebase'
import firebase from 'firebase'

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAMUfaROjMcZhV2SJijIcW-PGeKTRiDFkg",
  authDomain: "biotica-362a3.firebaseapp.com",
  databaseURL: "https://biotica-362a3.firebaseio.com",
  projectId: "biotica-362a3",
  storageBucket: "biotica-362a3.appspot.com",
  messagingSenderId: "456606469474",
  appId: "1:456606469474:web:3aaf4f0daeac836bbaf03b"
});

export const rsf = new ReduxSagaFirebase(firebaseApp);

export const messaging = null;//firebase.messaging();


export default rsf;