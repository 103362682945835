import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TimeRange from 'react-time-range';
import moment from 'moment';

import FA from 'react-fontawesome';


class TimeRangeTodoQuestion extends Component {
    constructor(props) {
        super(props);
        let dummyDate = new Date();
        dummyDate.setHours(12);
        dummyDate.setMinutes(0);

        this.state = {
            todo: [],
            startTime: dummyDate,
            endTime: dummyDate,
            dummyDate: dummyDate,
            optionsPickerPnt: 0
        }

        this.addItemTodo = this.addItemTodo.bind(this);
        this.setRange = this.setRange.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.removeItemTodo = this.removeItemTodo.bind(this);

    }


    nextQuestion() {
        const { nextQuest, question } = this.props;

        if(this.state.todo.length > 0){
            nextQuest(question, [...this.state.todo]);
            this.setState({
                todo: []
            })
        }

    }

    setRange({ endTime, startTime }) {
        this.setState({
            startTime: new Date(startTime),
            endTime: new Date(endTime)
        })
    }

    addItemTodo() {
        let todo = this.state.todo;

        todo.push({
            startTime: this.state.startTime,
            endTime: this.state.endTime
        });

        this.setState({
            todo,
            startTime: this.state.dummyDate,
            endTime: this.state.dummyDate
        })
    }

    removeItemTodo(indx) {
        let todo = this.state.todo;
        todo.splice(indx, 1);
        this.setState({ todo });
    }

    render() {
        const { question: { quest, desc } } = this.props;

        return (
            <div className="content-time-range-todo-question">

                <h2>{quest}</h2>
                <p>{desc}</p>


                <div className="content-time-range-todo-filed">
                    <TimeRange
                        startLabel="Inicio"
                        endLabel="Fin"
                        minuteIncrement={60}
                        sameIsValid={true}
                        showErrors={false}
                        startMoment={this.state.startTime}
                        endMoment={this.state.endTime}
                        onChange={this.setRange} />


                    <button onClick={this.addItemTodo} className={`add`}><FA name={"plus"} /></button>
                </div>
                <div className="content-time-range-todo">
                    {this.state.todo.map((item, indx) => (
                        <div className="item-time-range-todo">
                            <div>

                                <span>De  <small>{moment(item.startTime).format('h:mm a')}</small> </span>
                                <span> a <small>{moment(item.endTime).format('h:mm a')}</small> </span>
                            </div>
                            <div>
                                <button onClick={() => this.removeItemTodo(indx)} ><FA name={"remove"} /></button>
                            </div>
                        </div>
                    ))}

                </div>

                <button className={`callback ${this.state.todo.length > 0 ? "" : "disable"}`} onClick={this.nextQuestion} >Siguiente</button>
            </div>
        )
    }
}




const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(TimeRangeTodoQuestion);