import { SET_SATURATION_POINT_NOTIFICATION_REDUCER, LOGOUT_REDUCER } from '../constantsGlobal';

const initialState = {
    pointsNotification: []
}

function saturation(state = initialState, action) {
    switch (action.type) {
        case SET_SATURATION_POINT_NOTIFICATION_REDUCER:
            return Object.assign({}, state, {
                pointsNotification: action.payload
            });
        case LOGOUT_REDUCER:
            // console.log("saturation", LOGOUT_REDUCER)
            // return state
            return {
                pointsNotification: []
            };
        default:
            return state
    }
}

export default saturation;