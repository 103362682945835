import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TimeRange from 'react-time-range';
import moment from 'moment'

import Modal from '../../utils/modal'
// import { sourcerepo } from 'googleapis/build/src/apis/sourcerepo';
import {  putNewTasks, closeModal } from '../../actions/actions'

import { enumScheduleTypeText, optionsTaskModal } from '../../constantsGlobal'

class FormTask extends Component {
    constructor(props) {
        super(props);
        let dummyDate = new Date();
        dummyDate.setHours(12);
        dummyDate.setMinutes(0);

        this.state = {
            submitModal: false,
            taskName: "",
            enableOterOption: false,
            startTime: dummyDate,
            endTime: dummyDate
        }

        this.getBody = this.getBody.bind(this);
        this.createTask = this.createTask.bind(this);
        this.onClose = this.onClose.bind(this);
        this.setRange = this.setRange.bind(this);
    }

    setTaskOption(task) {
        this.setState({
            taskName: task !== "Otro" ? task : "",
            enableOterOption: task === "Otro"
        })
    }

    createTask() {
        const { putNewTasks, taskType,closeModal } = this.props;
        this.setState({
            submitModal: true
        })

        if (this.state.taskName && this.state.startTime < this.state.endTime) {
            putNewTasks({
                task: this.state.taskName,
                type: taskType,
                from: moment(this.state.startTime).format("HH:mm"),
                to: moment(this.state.endTime).format("HH:mm")
            });
            this.onClose();
            closeModal();
        }
    }

    onClose() {
        let dummyDate = new Date();
        dummyDate.setHours(12);
        dummyDate.setMinutes(0);

        this.setState({
            submitModal: false,
            taskName: "",
            enableOterOption: false,
            startTime: dummyDate,
            endTime: dummyDate,
            type: null
        })
    }

    setRange({ endTime, startTime }) {

        let prevStartTime = moment(this.state.startTime).format("LT");
        let _startTime = moment(startTime).format("LT");
        let _endTime = endTime;

        if (prevStartTime !== _startTime) {
            _endTime = startTime
        }

        this.setState({
            startTime: new Date(startTime),
            endTime: new Date(_endTime)
        })
    }


    getBody() {
        const { taskType } = this.props;
        let optionsModal = optionsTaskModal[taskType];

        return (<>
            <h4>Nueva Tarea {enumScheduleTypeText[taskType]}</h4>
            <div>
                {!optionsModal &&
                    <div className="input-content">
                        <label for="taskName">Tarea*</label>
                        <input name="taskName" id="taskName"
                            onChange={(e) => this.setState({
                                taskName: e.currentTarget.value
                            })}></input>
                        {(this.state.submitModal && this.state.taskName === "") && <p>Este campo es obligatorio</p>}
                    </div>
                }
                {optionsModal && <>
                    <div className="input-content">
                        <label for="taskProyectId">Tarea*</label>
                        <select id="taskProyectId" name="taskProyectId"
                            onChange={(e) => this.setTaskOption(e.currentTarget.value)}>
                            <option value={null} selected>Seleccione...</option>
                            {optionsModal.map(_option => (
                                <option value={_option}>{_option}</option>
                            ))}
                        </select>
                        {(this.state.submitModal && this.state.taskName === "" && !this.state.enableOterOption) && <p>Este campo es obligatorio</p>}
                    </div>
                    {this.state.enableOterOption &&
                        <div className="input-content">
                            <label for="taskName">Otro*</label>
                            <input name="taskName" id="taskName"
                                onChange={(e) => this.setState({
                                    taskName: e.currentTarget.value
                                })}></input>
                            {(this.state.submitModal && this.state.taskName === "") && <p>Este campo es obligatorio</p>}
                        </div>
                    }
                </>}


                <div className="input-content">
                    <label for="taskDescription">Hora*</label>

                    <TimeRange
                        startLabel="Inicio"
                        endLabel="Fin"
                        minuteIncrement={30}
                        showErrors={this.state.submitModal}
                        startMoment={this.state.startTime}
                        endMoment={this.state.endTime}
                        onChange={this.setRange} />


                    {(this.state.submitModal && this.state.startTime >= this.state.endTime ) && <p>Rango inválido.</p>}
                </div>


            </div>
        </>)
    }
    render() {
        return (
            <Modal body={this.getBody()}
                footer={(
                    <span onClick={this.createTask}>Crear</span>
                )}
                id="taskModal"
                onClose={this.onClose}
            />
        )
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    actorTools: state.utils.actorTools
});
const mapDispatchToProps = dispatch => bindActionCreators({
    putNewTasks, closeModal
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormTask);
