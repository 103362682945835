import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../styles/profile.css';

import { HOST_APP, avatares, fingerPrint, anhelosdef, habilidadesEsenciales } from '../constantsGlobal'

import { setInfoFooter } from '../actions/actions'

import { detalleAnhelos, } from '../resources/resources'




// import FA from 'react-fontawesome';
import ColumnChart from './charts/columnChart'

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consolidate: {},
            predominante: {},
            chartAnhelo: [],
            modalAsertOpen: false,
            showModal: false,
            viewHability: ""
        }
    }


    componentWillMount() {
        const { answers, anhelos, actor, history, setInfoFooter } = this.props;

        if (!actor.id) {
            history.push("/");
        }

        window.localStorage.removeItem('answers');
        window.localStorage.removeItem('infoContact');
        window.localStorage.removeItem('interestAct');
        window.localStorage.removeItem('interestInt');

        // debugger;
        let consolidate = {};
        answers.filter(answ => answ.idRespuesta != null).forEach(answ => {
            let _anhelos = anhelos.find(anh => anh.idRespuesta === answ.idRespuesta);
            if (_anhelos) {
                [].concat(_anhelos).forEach(anh => {
                    consolidate[anh.anhelo] = consolidate[anh.anhelo] ? consolidate[anh.anhelo] + 1 : 1;
                });
            }
        });

        let predominante = Object.keys(consolidate)
            .map(item => ({ "anhelo": item, "number": consolidate[item] }))
            .reduce((a, b, i) => {
                return a.number < b.number ? b : a
            }, { number: -1 })

        let chartAnhelo = Object.keys(consolidate).map(item => {
            return {
                name: item,
                value: consolidate[item],
                fullMark: 15
            }
        })

        this.setState({
            consolidate: consolidate,
            predominante: predominante,
            chartAnhelo
        })

        setInfoFooter({
            type: "route",
            childs: [
                { icon: "user-o", name: "Perfil", route: "/home/profile" },
                { icon: "road", name: "Ruta", route: "/home/route" },
                { icon: "comments-o", name: "Comentarios", route: "/home/feedback" }
                // { icon: "star-o", name: "Califícanos", route: "/home/survey", type:"out"}
            ],
            value: "Perfil"
        });
        // { icon: "users", name: "Comunidad", route: "/home/comunity", type: "out" }

    }


    render() {
        const { actor } = this.props;

        // let temores = eneaQuestios.filter(item => !!anhelosdef[this.state.predominante.anhelo] && item.anhelonumber == anhelosdef[this.state.predominante.anhelo].anhelonumber);
        let numeroAnhelo = this.state.predominante.anhelo && !!anhelosdef[this.state.predominante.anhelo] ? anhelosdef[this.state.predominante.anhelo].anhelonumber : 1;

        // let _predominante = Object.assign(this.state.predominante)  ;
        let _anhelosdef = Object.assign({}, anhelosdef[this.state.predominante.anhelo]);

        let dataDonut = Object.keys(this.state.consolidate).map(item => ({
            anheloNombre: Object.assign({}, anhelosdef[item]).centro,
            value: this.state.consolidate[item]
        }))

        return (
            <div className="content-profile">

                <div className="content-definition">
                    <div className="content-graph">
                        {/* <DonutChart data={dataDonut}/> */}
                        <h2 className="underline">Motivadores</h2>
                        <ColumnChart data={dataDonut} name="Motivadores" />
                    </div>

                    <div className="content-skills">
                        <h2 className="underline">Habilidades</h2>
                        <div className="content-powers">

                            {habilidadesEsenciales.map(({ habilidad, key }, indx) => {

                                return <div key={`skill_${indx}`} className={`${this.state.viewHability === habilidad ? "active-hability" : ""} item-power`} onClick={() => {
                                    this.setState({ viewHability: habilidad })
                                }} >
                                    {/* <img src={`./iconos/${key}.png`}></img> */}
                                    <h3 style={{ width: "fit-contet" }}>{habilidad}</h3>
                                    {/* <span className="callback">Conoce un poco más</span> */}
                                    <p className="description-view"  >
                                        {detalleAnhelos[numeroAnhelo][key].descripcion}
                                    </p>
                                    {/* <span className="close-view" onClick={(ev) => {
                                        ev.stopPropagation();
                                        this.setState({ viewHability: "" })
                                    }}>
                                        <FA name="times" /> </span> */}
                                </div>
                            })}

                        </div>


                    </div>
                </div>

                <div className="content-avatar-profile">
                    <img alt="avatar" src={`${HOST_APP}${avatares[actor.avatar]}`}></img>
                    <div>
                        <h2>{`${actor.nombre} ${actor.apellido}`}</h2>
                        <p>
                            <strong>Anhelo:</strong> {this.state.predominante.anhelo} - {fingerPrint[actor.huella]} <br />
                            <strong>Centro:</strong> {_anhelosdef.centro}

                        </p>
                    </div>
                    <div className="content-full">
                        <p>{_anhelosdef.descripcion} </p>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers,
    anhelos: state.diagnosis.anhelos,
    eneaQuestios: state.diagnosis.eneaQuestios
});
const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setInfoFooter
    }, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Profile);
