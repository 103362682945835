import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import QuestionAnswer from './questionAnswer';

import _ from 'lodash'
import FA from 'react-fontawesome';

class AnswerMatriz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            values: {},
            blockValues: {}
        }
        this.selectValue = this.selectValue.bind(this);


    }

    componentWillMount() {
        const { items, nItemsMatriz, value } = this.props;
        // console.log(value);
        this.setState({
            items,
            nItemsMatriz: _.fill(Array(nItemsMatriz), null),
            values: value.split(";").reduce((a, b) => {
                let answerValue = b.split("-");
                a[answerValue[0]] = answerValue[1]
                return a;
            }, {})
        })
    }

    selectValue(id, value) {
        const { selectAnswer } = this.props;

        let _values = Object.assign({}, this.state.values);
        _values[id] = value;

        // console.log(_values)

        let blockValues = Object.keys(_values).reduce((a, b) => {
            a[_values[b]] = a[_values[b]] === undefined ? false : true;
            return a;
        }, {})

        this.setState({
            values: _values,
            blockValues
        });

        let _valuesString = Object.keys(_values)
            .map(idAnswer => (`${idAnswer}-${_values[idAnswer]}`))
            .join(";")

        selectAnswer(_valuesString)
    }




    render() {
        // ${answers[question.id] == resp.id ? "active-radio" : ""} 
        // onChange={() => this.selectAnswer(resp.id)}
        return (
            <div className="content-answer-matriz">

                {this.state.nItemsMatriz.map((item, indx) => (<span style={{
                    gridColumn: `${indx + 2}`, gridRow: "1"
                }}>{indx + 1}</span>))}

                {this.state.items.map((item, indx) => (
                    <div style={{
                        gridColumn: "1", gridRow: `${indx + 2}`
                    }}>
                        <span>{item.respuesta}
                        </span>
                    </div>
                ))}

                {this.state.items.reduce((a, b, indx) => (
                    [...a, ...this.state.nItemsMatriz.map((item, indxSubItem) => ({
                        ...b, valieIndx: indxSubItem + 1, row: (indx + 2), column: (indxSubItem + 2)
                    }))]
                ), []).map(item => (
                    <div style={{
                        gridColumn: `${item.column}`, gridRow: `${item.row}`

                    }} >
                        <FA
                            name={this.state.values[item.id] === item.valieIndx ? "circle" : "circle-thin"}
                            style={{
                                textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',
                                color: this.state.blockValues[item.valieIndx] ? (
                                    this.state.values[item.id] === item.valieIndx ? "rgba(0,0,0, 0.3)" : "rgba(242, 242, 242, 0.5)"
                                ) : "rgba(0,0,0, 0.3)"
                            }}
                            onClick={() =>
                                this.state.blockValues[item.valieIndx] ?
                                    null
                                    : this.selectValue(item.id, item.valieIndx)
                            }
                        />
                    </div>
                ))}


            </div>
        )
    }

}

const mapStateToProps = state => ({});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(AnswerMatriz);
