import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { staturationQuestions, typeQeuestionsSaturation } from '../../resources/resources'


import PickerOptionsQuestion from './pickerOptionsQuestion'
import TimeRangeQuestion from './timeRangeQuestion'
import TimeRangeOptionsFieldQuestion from './timeRangeOptionsFieldQuestion'
import TimeRangeTodoQuestion from './timeRangeTodoQuestion'
import SaturationPointNotifications from './saturationPointNotifications'

import moment from 'moment';

class SaturationQuestionContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentOrder: 1,
            answers: {},
            topHoursSaturation: []
        }
        this.nextQuest = this.nextQuest.bind(this);
        this.compileData = this.compileData.bind(this);

    }

    componentWillMount() {
        const { actor, history } = this.props;
        if (!actor.id) {
            history.push("/");
            return;
        }
    }

    nextQuest(question, answer) {
        let _value = this.state.answers;

        _value[question.id] = {
            question,
            answer
        };

        this.setState({
            currentOrder: (question.order + 1),
            answers: _value
        })

        if (!staturationQuestions.find(item => item.order === (question.order + 1))) {
            this.compileData()
        }
    }

    getIntervalPickerOptions(question, answer) {

        let answersKeys = Object.keys(answer);

        return question.options
            .filter(item => answersKeys.indexOf(String(item.id)) > -1 && answer[item.id] > 0)
            .reduce((a, b, i) => {

                let init = b.min;
                while (init <= b.max) {
                    a[init] = question.clout;
                    init++;
                }
                let resumeCloutLimits = question.cloutLimits.reduce((a, b, i) => a + b, 0)
                if (resumeCloutLimits > 0) {
                    let [limitInit, limitEnd] = question.cloutLimits;
                    a[b.min - 1] = limitInit;
                    a[b.max + 1] = limitEnd;
                }
                return a;
            }, {})


    }

    getIntervalTimeRange(question, answer) {

        let resumeCloutLimits = question.cloutLimits.reduce((a, b, i) => a + b, 0)

        let [initDate, endDate] = answer

        let _initDate = (new Date(initDate)).getHours();
        let _endDate = (new Date(endDate)).getHours() + 23;

        let init = _initDate;
        let result = {}

        while (init <= _endDate) {

            let HH = init > 23 ? init - 23 : init;
            result[HH] = question.clout;

            if (resumeCloutLimits > 0) {
                let [limitInit, limitEnd] = question.cloutLimits;
                result[_initDate - 1] = limitInit;
                result[_endDate + 1] = limitEnd;
            }
            init++;
        }


        return result
    }

    getIntervalTimeRangeOptionsField(question, answer) {

        let intervals = Object.keys(answer).map(item => answer[item]);
        let resumeCloutLimits = question.cloutLimits.reduce((a, b, i) => a + b, 0)

        return intervals.map(item => ({
            endTime: (new Date(item.endTime)).getHours(),
            startTime: (new Date(item.startTime)).getHours()
        })).reduce((a, b) => {

            let init = b.startTime;
            while (init <= b.endTime) {
                a[init] = question.clout
                init++;
            }

            if (resumeCloutLimits > 0) {
                let [limitInit, limitEnd] = question.cloutLimits;
                a[b.startTime - 1] = limitInit;
                a[b.endTime + 1] = limitEnd;
            }

            return a;
        }, {})

    }


    getArrayIntervalHours({ question, answer }) {
        switch (question.type) {
            case typeQeuestionsSaturation.pickerOptions:
                return this.getIntervalPickerOptions(question, answer)
            case typeQeuestionsSaturation.timeRange:
                return this.getIntervalTimeRange(question, answer)
            case typeQeuestionsSaturation.timeRangeOptionsField:
                return this.getIntervalTimeRangeOptionsField(question, answer)
            case typeQeuestionsSaturation.timeRangeTodo:
                return this.getIntervalTimeRangeOptionsField(question, answer)
            default:
                return {}
        }
    }


    compileData() {
        let answers = this.state.answers;

        let matriz = Object.keys(answers)
            .map((item) => this.getArrayIntervalHours(answers[item]));

        let _matriz = matriz.reduce((a, b) => Object.keys(b).map(H => ({
            hour: H,
            clout: b[H]
        })).reduce((c, d) => {
            c[d.hour] = (c[d.hour] ? c[d.hour] : 0) + d.clout
            return c;
        }, a), {});

        let topHoursSaturation = Object.keys(_matriz).map(H => {
            let _date = new Date();
            _date.setHours(H);
            _date.setMinutes(0);
            return {
                hour: H,
                hourText: moment(_date).format('h:mm a'),
                date: _date,
                clout: _matriz[H]
            }
        }).sort((a, b) => b.clout - a.clout);

        this.setState({
            topHoursSaturation
        })
    }


    render() {
        return (
            <div className="saturation-content-questions">
                <div className="doot-saturation-content">
                    {staturationQuestions.map(item =>
                        <span className={`doot-saturation ${item.order === this.state.currentOrder ? "doot-selected" : ""}`}>
                        </span>
                    )}
                </div>

                <div className="queston-saturation-main">
                    {staturationQuestions.filter(item => item.order === this.state.currentOrder).map(item => {
                        switch (item.type) {
                            case typeQeuestionsSaturation.timeRange:
                                return <TimeRangeQuestion question={item} nextQuest={this.nextQuest} />
                            case typeQeuestionsSaturation.pickerOptions:
                                return <PickerOptionsQuestion question={item} nextQuest={this.nextQuest} />
                            case typeQeuestionsSaturation.timeRangeOptionsField:
                                return <TimeRangeOptionsFieldQuestion question={item} nextQuest={this.nextQuest} />
                            case typeQeuestionsSaturation.timeRangeTodo:
                                return <TimeRangeTodoQuestion question={item} nextQuest={this.nextQuest} />
                            default:
                                return <></>
                        }
                    })}

                    {!staturationQuestions.find(item => item.order === this.state.currentOrder) &&
                        <SaturationPointNotifications pointsNotification={this.state.topHoursSaturation} />
                    }

                </div>

            </div>
        )
    }

}



const mapStateToProps = state => ({
    actor: state.diagnosis.actor
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(SaturationQuestionContent);