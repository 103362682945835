
const initialState = {
    breatStatus: {
        run: false,
        countDown: 60,
        stepCount: 0,
        stepName: "Sostiene",
        stepTime: 2,
        stepAction: "AFTER-OUT"
    }
}

function breathTool(state = initialState, action) {
    switch (action.type) {

        case "PUT_BREATH_STATUS":
            return Object.assign({}, state, {
                breatStatus: action.payload
            });
        case "PUT_BREATH_TIMING":
            return Object.assign({}, state, {
                breatStatus: {
                    ...state.breatStatus,
                    countDown: action.payload
                }
            });
        case "CLEAR_BREATH_STATUS":
            return initialState;

        default:
            return state
    }
}

export default breathTool;