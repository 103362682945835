import { SET_DEFINITION_ITEM, UNSET_DEFINITION_ITEM, SET_TOOLS_REDUCER, LOGOUT_REDUCER } from '../constantsGlobal';

const initialState = {
    definitionItem: {},
    tools: { breathing: [] }
}

function definition(state = initialState, action) {
    switch (action.type) {
        case SET_DEFINITION_ITEM:
            return Object.assign({}, state, {
                definitionItem: action.payload
            });
        case SET_TOOLS_REDUCER:
            return Object.assign({}, state, {
                tools: action.payload
            });
        case UNSET_DEFINITION_ITEM:
            return Object.assign({}, state, {
                definitionItem: {}
            });
        case LOGOUT_REDUCER:
            // console.log("definition", LOGOUT_REDUCER)
            // return state
            return {
                definitionItem: {},
                tools: { breathing: [] }
            };
        default:
            return state
    }
}

export default definition;