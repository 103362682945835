import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment'
import { HOST_APP, enumScheduleType } from '../../constantsGlobal'
import FA from 'react-fontawesome'

import FormTask from './formTask'

import { openModal, deleteTask } from '../../actions/actions'
class TasksOfDay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            titleDay: "",
            currentTasksDay: [],
            resolveMessage: null,
            shwoAlert: false,
            alertMessage: ""
        }
        this.buildTasks = this.buildTasks.bind(this);
        this.createTask = this.createTask.bind(this);
    }


    componentDidMount() {
        const { currentDateScheduler } = this.props;
        this.buildTasks(currentDateScheduler);
    }

    buildTasks(currentDateScheduler) {
        const { actorTools } = this.props;
        let scheduler = actorTools ? Object.assign({}, actorTools.scheduler) : {};


        let currTasksObj = Object.assign({}, scheduler[currentDateScheduler]);
        let currentTasksDay = Object.keys(currTasksObj).map(item => ({
            ...currTasksObj[item],
            currFromDate: `${currentDateScheduler}T${item.from}:00-05:00`,
            idTask: item
        }))

        this.setState({
            currentTasksDay
        })
    }

    componentWillUpdate(next) {
        const { currentDateScheduler } = this.props;


        let scheduler = next.actorTools ? Object.assign({}, next.actorTools.scheduler) : {};
        let currTasksObj = Object.assign({}, scheduler[currentDateScheduler]);
        let currentTasksDay = Object.keys(currTasksObj)

        if (next.currentDateScheduler !== currentDateScheduler ||
            this.state.currentTasksDay.length !== currentTasksDay.length) {
            this.buildTasks(next.currentDateScheduler);
        }
    }

    createTask(_enum) {
        const { openModal } = this.props;
        this.setState({ taskType: _enum })
        openModal("taskModal");
    }

    removeTask(idTask) {

        const { deleteTask } = this.props;

        new Promise((resolve) => {
            this.setState({
                resolveMessage: resolve,
                shwoAlert: true,
                alertMessage: `Esta seguro de eliminar la tarea.`
            })
        }).then((result) => {
            if (result)
                deleteTask({
                    taskId: idTask
                });


            this.setState({
                shwoAlert: false,
                resolveMessage: null,
                alertMessage: ""
            })

        })
    }

    render() {
        const { currentDateScheduler } = this.props
        return (<>
            <h3>{moment(currentDateScheduler).format("dddd D [de] MMMM")}  </h3>
            <div className="content-callback-task">
                <h4>Nueva tarea</h4>
                <div onClick={() => this.createTask(enumScheduleType.HOBBIES)}>
                    <img alt="Hobbies" src={`${HOST_APP}motivadores/HOBBIES.png`} />
                    <span>Hobbies</span>
                </div>
                <div onClick={() => this.createTask(enumScheduleType.BREACK)}>
                    <img alt="Descanso" src={`${HOST_APP}motivadores/BREACK.png`} />
                    <span>Descanso</span>
                </div>
                <div onClick={() => this.createTask(enumScheduleType.JOB)}>
                    <img alt="Trabajo" src={`${HOST_APP}motivadores/JOB.png`} />
                    <span>Trabajo</span>
                </div>
            </div>

            <div className="content-task">
                {this.state.currentTasksDay.map(item => (
                    <div className="item-task">
                        <div>
                            <img alt="tipo" src={`${HOST_APP}motivadores/${item.type}.png`} />
                        </div>
                        <div>
                            <h4>{item.task}</h4>
                            <div>
                                <FA name="clock-o" />
                                <span>
                                    {moment(`${currentDateScheduler}T${item.from}:00-05:00`).format("LT")} - {moment(`${currentDateScheduler}T${item.to}`).format("LT")}
                                </span>
                            </div>
                        </div>
                        {/* */}
                        <FA name="remove" onClick={() => this.removeTask(item.idTask)} />
                    </div>
                ))}

            </div>
            <FormTask taskType={this.state.taskType} />
            {this.state.shwoAlert && <div className="alert-confirm">

                <p>{this.state.alertMessage}</p>
                <div>
                    <span onClick={() => this.state.resolveMessage(true)} >Aceptar</span>
                    <span onClick={() => this.state.resolveMessage(false)} >Cancelar</span>
                </div>
            </div>}
        </>)
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    actorTools: state.utils.actorTools,
    currentDateScheduler: state.utils.currentDateScheduler

});
const mapDispatchToProps = dispatch => bindActionCreators({
    openModal, deleteTask
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(TasksOfDay);
