import {
    OPEN_MODAL_REDUCE, CLOSE_MODAL_REDUCE, SET_ALERT,
    UNSET_ALERT, SET_CONFIG_REDUCER, SET_USER_TOOLS_STATE_SAGA,
    SET_CURRENT_DATE_SCHEDULER, SET_VALIDATE_FORM_QUESTIONS_REDUCER, LOGOUT_REDUCER
} from '../constantsGlobal';
import moment from 'moment';

const initialState = {
    modalId: "",
    alertMessage: "",
    alertTime: 0,
    alertType: 'info',
    config: {},
    actorTools: {},
    validFormQuetions: true,
    currentDateScheduler: String(moment(new Date()).format()).substring(0, 10)
}

function utils(state = initialState, action) {
    switch (action.type) {
        case SET_VALIDATE_FORM_QUESTIONS_REDUCER:
            // console.log(action.payload)
            return Object.assign({}, state, {
                validFormQuetions: action.payload
            });
        case SET_USER_TOOLS_STATE_SAGA:
            // console.log(action.payload)
            return Object.assign({}, state, {
                actorTools: action.payload
            });
        case SET_CONFIG_REDUCER:
            return Object.assign({}, state, {
                config: action.payload
            });
        case OPEN_MODAL_REDUCE:
            return Object.assign({}, state, {
                modalId: action.payload
            });
        case CLOSE_MODAL_REDUCE:
            return Object.assign({}, state, {
                modalId: null
            });
        case SET_ALERT:
            return Object.assign({}, state, {
                alertMessage: action.payload.message ? action.payload.message : "",
                alertTime: action.payload.time ? action.payload.time : 0,
                alertType: action.payload.alertType ? action.payload.alertType : 'info'
            });
        case UNSET_ALERT:
            return Object.assign({}, state, {
                alertMessage: "",
                alertTime: 0,
                alertType: 'info'
            });
        case SET_CURRENT_DATE_SCHEDULER:
            return Object.assign({}, state, {
                currentDateScheduler: action.payload
            });
        case LOGOUT_REDUCER:
            // console.log("utils", LOGOUT_REDUCER)
            // return state
            return {
                modalId: "",
                alertMessage: "",
                alertTime: 0,
                alertType: 'info',
                config: {},
                actorTools: {},
                validFormQuetions: true,
                currentDateScheduler: String(moment(new Date()).format()).substring(0, 10)
            };

        default:
            return state
    }
}

export default utils;