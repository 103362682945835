import {
    SET_CONTEXT_FEEDBACK_REDUCER, SET_CURRENT_CONTEXT_CATEGORY_REDUCER,
    UNSET_CURRENT_CONTEXT_CATEGORY_REDUCER, GET_FRECUENT_FEEDBACK_CATEGORIES_REDUCER, LOGOUT_REDUCER
} from '../constantsGlobal';

const initialState = {
    contexts: [],
    frecuentCategories: [],
    currentContext: {},
    curretnCategory: {}
}

function feedback(state = initialState, action) {
    switch (action.type) {
        case SET_CONTEXT_FEEDBACK_REDUCER:
            return Object.assign({}, state, {
                contexts: action.payload
            });
        case SET_CURRENT_CONTEXT_CATEGORY_REDUCER:
            return Object.assign({}, state, {
                currentContext: action.payload.context,
                curretnCategory: action.payload.category
            });
        case UNSET_CURRENT_CONTEXT_CATEGORY_REDUCER:
            return Object.assign({}, state, {
                currentContext: {},
                curretnCategory: {}
            });
        case GET_FRECUENT_FEEDBACK_CATEGORIES_REDUCER:
            return Object.assign({}, state, {
                frecuentCategories: action.payload
            });
        case LOGOUT_REDUCER:
            // console.log("feedback", LOGOUT_REDUCER)
            // return state;
            return {
                contexts: [],
                frecuentCategories: [],
                currentContext: {},
                curretnCategory: {}
            }

        default:
            return state
    }
}

export default feedback;