import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { HOST_APP } from '../constantsGlobal'

import { setInfoFooter, getFrecuentFeedbackCategories } from '../actions/actions'

// import FA from 'react-fontawesome';




class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consolidate: {},
            predominante: {},
            chartAnhelo: [],
            modalAsertOpen: false,
            showModal: false,
            viewHability: ""
        }
    }


    componentWillMount() {
        const { actor, history, setInfoFooter, getFrecuentFeedbackCategories } = this.props;

        if (!actor.id) {
            history.push("/");
        }

        window.localStorage.removeItem('answers');
        window.localStorage.removeItem('infoContact');
        window.localStorage.removeItem('interestAct');
        window.localStorage.removeItem('interestInt');

        setInfoFooter({
            type: "route",
            childs: [
                { icon: "user-o", name: "Perfil", route: "/home/profile" },
                { icon: "road", name: "Ruta", route: "/home/route" },
                { icon: "comments-o", name: "Comentarios", route: "/home/feedback" }
                // { icon: "star-o", name: "Califícanos", route: "/home/survey", type:"out"}
            ],
            value: "Perfil"
        });

        getFrecuentFeedbackCategories({
            idCompany: actor.empresa,
            idActor: actor.id
        })


    }


    render() {
        const { frecuentCategories } = this.props;

        return (
            <div className="content-dashboard">

                <div className="main-dashboard">
                    <h3>Recomendaciones</h3>
                    <ul>
                        <li><span>9 am.</span>Respiración conciente durante un minuto</li>
                        <li><span>11 am.</span>Escribe los pensamientos mas recurrentes durante tu mañana</li>
                        <li><span>4 pm.</span>Respiración conciente durante un minuto</li>
                        <li><span>6 pm.</span>Escribe los pensamientos mas recurrentes durante tu tarde</li>
                    </ul>
                </div>
                <div className="dashboard-notifications-content"
                    style={{ backgroundImage: `url(${HOST_APP}/dash-bck-burble.png)` }} >
                    <h3>Notificaciones</h3>
                    <div>
                        <span>hace 2 minutos</span>
                        <p><strong>Solicitud feedback:</strong> comportamiento en reuniones</p>
                    </div>
                    <div>
                        <span>hace 5 minutos</span>
                        <p><strong>Solicitud feedback:</strong> comportamiento en reuniones</p>
                    </div>
                    <div>
                        <span>hace 1 hora</span>
                        <p><strong>Solicitud feedback:</strong> comportamiento en reuniones</p>
                    </div>
                </div>
                <div className="quick-access">
                    <h3>Feedback frecuente</h3>
                    <div>
                        {frecuentCategories.map(item => (<div>
                            <h5>{item.nombre}</h5>
                            <span>{item.contexto}</span>
                        </div>))}

                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    questios: state.diagnosis.questios,
    answers: state.diagnosis.answers,
    anhelos: state.diagnosis.anhelos,
    eneaQuestios: state.diagnosis.eneaQuestios,
    frecuentCategories: state.feedback.frecuentCategories
});
const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setInfoFooter,
        getFrecuentFeedbackCategories
    }, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
