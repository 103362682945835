import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment'
import FA from 'react-fontawesome'
import 'rc-time-picker/assets/index.css';
// import TimePicker from 'rc-time-picker';

import { putNewProyect, deleteProyect, putNewTasks, deleteTask, setCurrentDateScheduller } from '../actions/actions'

import { enumScheduleType, optionsTaskModal } from '../constantsGlobal'

import DaysOfWeek from './scheduler/daysOfWeek'
import TasksOfDay from './scheduler/tasksOfDay'
import NotificationsWeek from './scheduler/notificationsWeek'

class Scheduler extends Component {


    constructor(props) {
        super(props);

        this.state = {
            proyectName: "",
            taskProyectId: "",
            taskName: "",
            taskDescription: "",
            timePickervalue: moment(new Date()),
            showModalProyect: false,
            showModalTask: false,
            submitModalProyect: false,
            submitModalTask: false,
            shwoAlert: false,
            resolveMessage: null,
            alertMessage: "",
            showModalTaskOptions: false,
            modalTaskOptions: [],
            enableOterOption: false
        }

        this.setTaskOption = this.setTaskOption.bind(this);
        this.removeProyect = this.removeProyect.bind(this);
        this.removeTask = this.removeTask.bind(this);
        this.createProyect = this.createProyect.bind(this);
        this.createTask = this.createTask.bind(this);
        this.createTaskProyect = this.createTaskProyect.bind(this);
        this.setCurrDateScheduler = this.setCurrDateScheduler.bind(this);

    }


    componentWillMount() {

        const { actor, history } = this.props;

        if (!actor.id) {
            history.push("/");
        }
    }
    componentWillUnmount() {
        if (this.state.resolveMessage) {
            this.state.resolveMessage(false)
        }
    }

    removeProyect(idProyect) {
        const { deleteProyect } = this.props;

        new Promise((resolve) => {
            this.setState({
                resolveMessage: resolve,
                shwoAlert: true,
                alertMessage: `Esta seguro de eliminar el proyecto.`
            })
        }).then((result) => {
            if (result)
                deleteProyect(idProyect);


            this.setState({
                shwoAlert: false,
                resolveMessage: null,
                alertMessage: ""
            })

        })
    }

    removeTask(idTask, idProyect) {

        const { deleteTask } = this.props;

        new Promise((resolve) => {
            this.setState({
                resolveMessage: resolve,
                shwoAlert: true,
                alertMessage: `Esta seguro de eliminar la tarea.`
            })
        }).then((result) => {
            if (result)
                deleteTask({
                    proyectId: idProyect,
                    taskId: idTask
                });


            this.setState({
                shwoAlert: false,
                resolveMessage: null,
                alertMessage: ""
            })

        })
    }

    createProyect() {
        const { putNewProyect } = this.props;
        this.setState({
            submitModalProyect: true
        })

        if (this.state.proyectName) {
            putNewProyect(this.state.proyectName);
            this.setState({
                proyectName: "",
                submitModalProyect: false,
                showModalProyect: false
            })
        }
    }

    createTask() {
        const { putNewTasks } = this.props;
        this.setState({
            submitModalTask: true
        })

        if (this.state.taskName && this.state.taskProyectId && this.state.timePickervalue) {
            putNewTasks({
                name: this.state.taskName,
                proyectId: this.state.taskProyectId,
                description: this.state.taskDescription,
                taskDate: this.state.timePickervalue.toDate().getTime()
            });

            this.setState({
                taskName: "",
                taskProyectId: "",
                taskDescription: "",
                timePickervalue: moment(new Date()),
                submitModalTask: false,
                showModalTask: false,
                showModalTaskOptions: false,
                enableOterOption: false,
                modalTaskOptions: []
            })
        }
    }

    setTaskOption(task) {
        this.setState({
            taskName: task !== "Otro" ? task : "",
            enableOterOption: task === "Otro"
        })
    }

    createTaskProyect(idProyect) {
        let optionsModal = [enumScheduleType.BREACK, enumScheduleType.HOBBIES];
        this.setState({
            showModalTaskOptions: optionsModal.indexOf(idProyect) > -1,
            showModalTask: optionsModal.indexOf(idProyect) === -1,
            taskProyectId: idProyect,
            modalTaskOptions: optionsTaskModal[idProyect]
        })

    }

    setCurrDateScheduler(weekNumber) {
        const { setCurrentDateScheduller } = this.props;

        let _currDate = moment().week(weekNumber).startOf("week").add(1, 'd');
        setCurrentDateScheduller(_currDate.format().substring(0, 10));
    }

    render() {
        const { currentDateScheduler } = this.props;
        let from_date = moment(currentDateScheduler).startOf('week').add(1, 'd');
        let to_date = moment(currentDateScheduler).endOf('week').add(1, 'd');
        let weekNumber = moment(currentDateScheduler).week();

        if (moment(currentDateScheduler).format('d') === "0") {
            weekNumber = moment(currentDateScheduler).subtract(1, 'd').week();
        }

        // console.log(moment().week().startOf('week'));
        return (
            <div className="content-scheduler">

                <h2>Planifica tu día
                    <div className="week-peeker">
                        <FA name="angle-left" onClick={()=>this.setCurrDateScheduler(weekNumber - 1)} />
                        <span>{moment(from_date).format("dddd D").toLocaleUpperCase()} A {moment(to_date).format("dddd D").toLocaleUpperCase()}</span>
                        <FA name="angle-right"  onClick={()=>this.setCurrDateScheduler(weekNumber + 1)}/>
                    </div>
                </h2>

                <div className="content-main-scheduler">
                    <div className="content-dow">
                        <DaysOfWeek weekNumber={weekNumber} />
                    </div>
                    <div className="content-tod">
                        <TasksOfDay />
                    </div>
                    <div className="content-def">
                        <NotificationsWeek />
                    </div>
                </div>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    actorTools: state.utils.actorTools,
    currentDateScheduler: state.utils.currentDateScheduler
});
const mapDispatchToProps = dispatch => bindActionCreators({
    putNewProyect, deleteProyect, putNewTasks, deleteTask, setCurrentDateScheduller
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
