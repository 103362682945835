import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setCurrentContextCategoryFeedback, unsetCurrentContextCategoryFeedback, setFeedbackContextActor } from '../actions/actions'

import {
    HOST_APP
} from '../constantsGlobal'

// import FA from 'react-fontawesome';
import { ReactSVG } from 'react-svg';


class FeedbackDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            commentIsEmpety: true,
            optionValue: null,
            comment: "",
            requiredfiled: false,
            isWizard: false,
            btrCategories: []
        }
        this.selectOptionFeedback = this.selectOptionFeedback.bind(this);
        this.setCommentFeedback = this.setCommentFeedback.bind(this);
        this.sendFeedBack = this.sendFeedBack.bind(this);
        this.setNewDot = this.setNewDot.bind(this);

        this.textareaRef = React.createRef();
    }


    componentWillMount() {
        const { currentContext, curretnCategory } = this.props;

        let btrCategories = Object.assign([], currentContext.p_feedback_categories)
            .filter(item => item.nombre === curretnCategory.nombre);


        this.setState({
            isWizard: btrCategories.length > 1,
            btrCategories: btrCategories.sort((a, b) => a.id - b.id)
        })


    }

    componentWillUnmount() {
        const { unsetCurrentContextCategoryFeedback } = this.props;
        unsetCurrentContextCategoryFeedback();
    }


    selectOptionFeedback(idOption) {
        this.setState({
            optionValue: idOption
        })
    }

    setCommentFeedback(value) {
        this.setState({
            commentIsEmpety: !value,
            comment: value
        })
    }

    sendFeedBack() {
        const { currentContext, curretnCategory, setFeedbackContextActor, actor, history, origin } = this.props;

        if (
            (curretnCategory.typo === 2 && this.state.optionValue === null) || (curretnCategory.typo !== 2 && this.state.comment === "")
        ) {
            this.setState({
                requiredfiled: true
            })
            return;
        }

        setFeedbackContextActor({
            idActor: actor.id,
            idContext: currentContext.id,
            idCategoria: curretnCategory.id,
            valor: this.state.optionValue,
            detalle: this.state.comment
        })

        history.push(origin);
        // history.push('/home/feedback/');



    }

    setNewDot(category) {
        const { currentContext, setCurrentContextCategoryFeedback } = this.props;

        setCurrentContextCategoryFeedback({
            context: currentContext, category
        })

        this.textareaRef.current.value = "";

    }

    render() {
        const { currentContext, curretnCategory, hideTitle } = this.props;

        // console.log(this.state.btrCategories, curretnCategory);

        let currentIndexCategory = this.state.btrCategories.map(item => item.id).indexOf(curretnCategory ? curretnCategory.id : "") + 1;



        return (
            <div className="content-feedback-detail">
                {this.state.btrCategories.length > 1 &&
                    <div className="content-dot">
                        {this.state.btrCategories.map((category, indx) => (<div className={(indx + 1) === currentIndexCategory ? "active" : ""}>
                            {/* <span onClick={() => this.setNewDot(category)} > </span> */}
                            <span onClick={() => this.setNewDot(category)} > </span>
                        </div>))}
                    </div>
                }

                {!hideTitle && <>
                    <h2>{curretnCategory.nombre}</h2>
                    <small>{currentContext.contexto}</small>
                </>
                }


                <span className="content-description" >{curretnCategory.descripcion}</span>

                {curretnCategory.typo === 2 &&
                    <div className={`content-feedback-options ${this.state.requiredfiled ? 'required-filed' : ''}`}  >
                        {curretnCategory.p_feedback_categories_options.map(opt => (
                            <div className={`content-feedback-options-item ${opt.id === this.state.optionValue ? 'active' : ''}`}
                                onClick={() => this.selectOptionFeedback(opt.id)}>
                                <ReactSVG wrapper="span" className="option-icon-check"
                                    src={`${HOST_APP}check.svg`} />
                                <span> {opt.nombre} </span>
                            </div>
                        ))}
                    </div>
                }
                {curretnCategory.typo === 2 &&
                    <span style={{ marginBottom: "0.5rem", display: "block" }}>
                        Comentarios
                    </span>
                }

                <div className={curretnCategory.typo !== 2 && this.state.requiredfiled ? 'required-filed' : ''} >
                    <textarea ref={this.textareaRef} className={this.state.commentIsEmpety ? "tEmpty" : ""} cols='60' rows='8'
                        onChange={(e) => this.setCommentFeedback(e.currentTarget.value)}>
                    </textarea>
                    <span>
                        Comentarios constructivos <br />
                        Oportunidades de mejora <br />
                        Considera la personalidad <br />
                        No quejas o reclamos <br />
                        Reconoce fortalezas
                    </span>
                </div>

                {
                    currentIndexCategory === this.state.btrCategories.length &&
                    <div>
                        <button className={this.state.optionValue === null && this.state.comment === "" ? 'disable' : ''}
                            onClick={this.sendFeedBack}>Enviar</button>
                    </div>
                }

                {
                    currentIndexCategory < this.state.btrCategories.length &&
                    <div>
                        <button className={this.state.optionValue === null && this.state.comment === "" ? 'disable' : ''}
                            onClick={() => this.setNewDot(this.state.btrCategories[currentIndexCategory])}>Siguiente</button>
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    contexts: state.feedback.contexts,
    // currentContext: state.feedback.currentContext,
    // curretnCategory: state.feedback.curretnCategory
});
const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setCurrentContextCategoryFeedback,
        unsetCurrentContextCategoryFeedback,
        setFeedbackContextActor
    }, dispatch)
);


export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDetail);
