import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeModal } from '../actions/actions';

import FA from 'react-fontawesome'


class Modal extends Component {

    closeModal() {
        const { closeModal, onClose } = this.props;
        if (onClose)
            onClose();

        closeModal();
    }

    render() {

        const { body, footer, id, modalId } = this.props;

        return (<>
            {id === modalId &&
                <div className={`modal-content ${id !== modalId ? "modal-close" : ""}`}>
                    <div className="modal">
                        <div className="modal-section-top">
                            <FA name="times" onClick={() => this.closeModal()} />
                        </div>
                        <div className="modal-section-body">
                            {body}
                        </div>
                        <div className="modal-section-bottom">
                            {footer}
                        </div>
                    </div>
                    <div className="modal-fade" onClick={() => this.closeModal()}> </div>
                </div>
            }
        </>);
    }
}

const mapStateToProps = state => ({
    modalId: state.utils.modalId
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        closeModal
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(Modal);
