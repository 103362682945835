import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FA from 'react-fontawesome'
import moment from 'moment'
import { enumScheduleType } from '../../constantsGlobal'

class NotificationsWeek extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: []
        }

        this.buildNotification = this.buildNotification.bind(this);
    }

    componentDidMount() {
        const { currentDateScheduler, actorTools } = this.props;
        this.buildNotification(currentDateScheduler, actorTools);
    }

    componentWillUpdate(next) {
        const { currentDateScheduler, actorTools } = this.props;


        let _scheduler = actorTools ? Object.assign({}, actorTools.scheduler) : {};
        let _currTasksObj = Object.assign({}, _scheduler[next.currentDateScheduler]);
        let _currentTasksDay = Object.keys(_currTasksObj).length;

        let scheduler = next.actorTools ? Object.assign({}, next.actorTools.scheduler) : {};
        let currTasksObj = Object.assign({}, scheduler[next.currentDateScheduler]);
        let currentTasksDay = Object.keys(currTasksObj).length;

        if (next.currentDateScheduler !== currentDateScheduler || _currentTasksDay !== currentTasksDay) {
            this.buildNotification(next.currentDateScheduler, next.actorTools);
        }
    }

    buildNotification(currentDateScheduler, actorTools) {

        let scheduler = actorTools ? Object.assign({}, actorTools.scheduler) : {};


        let currTasksObjToday = Object.assign({}, scheduler[currentDateScheduler]);

        let countBreackToday = Object.keys(currTasksObjToday)
            .map(item => currTasksObjToday[item])
            .filter(item => enumScheduleType.BREACK === item.type)
            .length;

            let currentWeekDateScheduler = currentDateScheduler;
        if (moment(currentWeekDateScheduler).format("d") === "0") {
            currentWeekDateScheduler = moment(currentDateScheduler).subtract(1, 'd');
        }

        let from_date = moment(currentWeekDateScheduler).startOf('week').add(1, 'd');
        let to_date = moment(currentWeekDateScheduler).endOf('week').add(2, 'd');

        let currDate = from_date.format();
        let dow = [];

        while (!to_date.isSame(currDate, 'day')) {
            dow.push(
                moment(currDate).format().substring(0, 10)
            );
            currDate = moment(currDate).add(1, 'd').format();
        }

        
        let numHobbies = dow.reduce((a, b) => {
            let _currTasksObj = Object.assign({}, scheduler[b]);
            return a + Object.keys(_currTasksObj).map(item => _currTasksObj[item])
                .filter(item => enumScheduleType.HOBBIES === item.type)
                .length;
        }, 0)


        let breackNotificationMessaje = countBreackToday < 3 ?
            `Te ${countBreackToday === 1 ? "falta" : "faltan"} ${3 - countBreackToday} ${countBreackToday === 1 ? "pausa activa" : "pausas activas"} de minimo 5 minutos, para el dia de hoy.`
            : "has cumplido tu cuota de breaks para el dia de hoy."

        let breackNotification = {
            status: countBreackToday < 3 ? "danger" : "success",
            message: breackNotificationMessaje,
            title: countBreackToday < 3 ? "Atención!" : "Felicidades!"
        }


        let hobbiesNotificationMessaje = numHobbies < 5 ?
            `Llevas ${numHobbies} ${numHobbies === 1 ? "hobby" : "hobbies"} esta estamana, te faltan ${5 - numHobbies}.`
            : "has cumplido tu cuota de hobbies para esta semana."


        let hobbiesNotification = {
            status: numHobbies < 5 ? "danger" : "success",
            message: hobbiesNotificationMessaje,
            title: numHobbies < 5 ? "Animo!!" : "Felicidades!"
        }

        let timeBreathing = (actorTools && actorTools.timeBreathing) ? Number(actorTools.timeBreathing / 60).toFixed(1) : 0;
        let breathNotification = {
            status: timeBreathing < 3 ? "warning" : "success",
            message: `Llevas ${timeBreathing} ${timeBreathing === 1 ? 'minuto' : "minutos"} de respiración.`,
            title: timeBreathing < 3 ? "Animo!!" : "Felicidades!"
        }

        this.setState({
            notifications: [
                breackNotification,
                hobbiesNotification,
                breathNotification
            ]
        })
    }



    render() {
        const { currentDateScheduler: _currentDateScheduler } = this.props;
        let currentDateScheduler = _currentDateScheduler;
        if (moment(currentDateScheduler).format("d") === "0") {
            currentDateScheduler = moment(_currentDateScheduler).subtract(1, 'd')
        }

        let from_date = moment(currentDateScheduler).startOf('week').add(1, 'd');
        let to_date = moment(currentDateScheduler).endOf('week').add(1, 'd');

        return (<>
            <h3>Semana</h3>
            <span>{moment(from_date).format("dddd D").toLocaleLowerCase()} a {moment(to_date).format("dddd D").toLocaleLowerCase()}</span>
            <div className="content-task-notification" >
                {this.state.notifications.map(item => (
                    <div className={`item-notification notification-${item.status}`}>
                        <p>{item.title},<br /> {item.message}</p>

                        <FA name="info-circle" />
                    </div>
                ))}
            </div>
        </>)
    }
}


const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    actorTools: state.utils.actorTools,
    currentDateScheduler: state.utils.currentDateScheduler
});
const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(NotificationsWeek);