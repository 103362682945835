import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDefinitionItem } from '../actions/actions'


class BreathingsTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consolidate: {},
            predominante: {},
            chartAnhelo: [],
            modalAsertOpen: false,
            showModal: false
        }

        this.selectBreathTool = this.selectBreathTool.bind(this);
    }



    componentWillMount() {

        const { actor, history } = this.props;

        if (!actor.id) {
            history.push("/");
        }
    }

    selectBreathTool(breathTool) {
        const { tools: { breathing }, setDefinitionItem, history, feedbackTools, configBreathDefault } = this.props;


        setDefinitionItem({
            ...breathTool,
            currentBreathing: !!breathing.find(brth => brth.id === configBreathDefault.id),
            contextId: feedbackTools.breathing.id,
            categoryId: feedbackTools.breathing.category.feedback,
            origin: "/home/breathingTools/"
        });

        history.push("/home/definitionBreath/");

    }

    render() {
        const { tools: { breathing } } = this.props;


        return (
            <div className="content-breathing-tools">

                <h2>Respiración</h2>


                {/* <MainToolBreath history={history} /> */}

                <div className="content-tools">
                    {breathing.map((brth, indx) => (
                        <div key={`breath-item_${indx}`} className="breath-item" onClick={() => this.selectBreathTool(brth)}>
                            <h4>{brth.title}</h4>
                            <span>{brth.description}</span>
                            <div className="content-callback">
                                <span>Ver mas</span>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        )
    }
}




const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    definitionItem: state.definition.definitionItem,
    tools: state.definition.tools,
    feedbackTools: Object.assign({}, state.utils.config.feedbackTools),
    configBreathDefault: Object.assign({}, state.utils.config.breath)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setDefinitionItem
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(BreathingsTools);
