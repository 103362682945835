import React, { Component } from 'react';
import { connect } from 'react-redux';


import ApexCharts from 'react-apexcharts';
// import _ from 'lodash'



const colors_palete = [
    '#5C65AD',
    '#979AB4',
    '#8885A3',
    '#BDC3DE',
    '#57B5CD'
]

class ColumnChart extends Component {
    constructor(props) {
        super(props)


        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false
                    }
                },
                colors: colors_palete,
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '40%',
                        distributed: true
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    labels:{
                        style:{
                            colors:"#474857"
                        }
                    }
                },
                yaxis: {
                    labels:{
                        style:{
                            color:"#474857"
                        }
                    }
                },

                fill: {
                    opacity: 1
                }
            }


        };
    }

    render() {
        const { data, name } = this.props;

        let _options = Object.assign(this.state.options);
        _options.xaxis.categories = data.map(item => item.anheloNombre);
        let series = [{
            name: name,
            data: data.map(item => item.value)
        }
        ]

        return (
            <ApexCharts options={_options} series={series} type="bar" height={200} />
        )
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = state => ({});



export default connect(mapStateToProps, mapDispatchToProps)(ColumnChart);



