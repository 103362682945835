import {
    breathConstants, HOST_APP
} from '../constantsGlobal'

import { call, put, takeLatest, select, take, cancelled } from 'redux-saga/effects'

import { eventChannel, END } from 'redux-saga'


const audioInit = new Audio(`${HOST_APP}/sounds/tibetan.mp3`);
const audioFinish = new Audio(`${HOST_APP}/sounds/tibetan.mp3`);

let chan = null;

/**
 * limitTime --> tiempo en segundos
 */
function counterBreath({ limitTime, steps }) {
    const { action, stepsText, stepSequence } = breathConstants;

    return eventChannel(emitter => {

        let currTimeStep = 0;
        let currActionT = steps[action];
        let currAction = action;
        let _limitTime = limitTime;

        const iv = setInterval(() => {

            if (currTimeStep === currActionT) {
                currTimeStep = 0;
                currAction = stepSequence.filter((key, indx) => stepSequence.indexOf(currAction) + 1 === indx)
                    .reduce((a, b) => b, action)
                currActionT = steps[currAction];
            }
            currTimeStep++;
            _limitTime--;

            let result = {
                run: (_limitTime > 0),
                countDown: _limitTime > 0 ? _limitTime : 60,
                stepCount: currTimeStep,
                stepName: stepsText[currAction],
                stepTime: currActionT,
                stepAction: currAction
            }

            emitter(result);

            if (_limitTime === 0) {
                audioFinish.play();
                emitter(END);
            }

        }, 1000);

        return () => {
            clearInterval(iv)
        }
    }
    )
}


export function* breathAction({ payload }) {

    const { definitionItem } = yield select(state => state.definition);
    const { countDown } = yield select(state => state.breathTool.breatStatus);

    const steps = Object.assign([], definitionItem.steps)
        .filter(step => step.key)
        .reduce((a, b) => {
            a[b.key] = b.time;
            return a;
        }, breathConstants.steps)

    audioInit.play();

    chan = yield call(counterBreath, { limitTime: countDown, steps })

    try {
        while (true) {
            // take(END) will cause the saga to terminate by jumping to the finally block
            let result = yield take(chan)

            yield put({
                type: "PUT_BREATH_STATUS", payload: result
            });
        }
    } finally {
        if (yield cancelled()) {
            chan.close()
            console.log('countdown cancelled')
        }

        yield put({
            type: "CLEAR_BREATH_STATUS"
        });
    }

}



export function* breathActionSaga() {
    yield takeLatest("INIT_BREATHING", breathAction);
}

export function* stopBreathChanelSaga() {
    yield takeLatest("STOP_BREATHING", () => {
        if (chan)
            chan.close();
    });
}
