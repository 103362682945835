import {
    LOGIN_SERVICE, LOGIN_REDUCE, LOGIN_SAGA, SET_ACTOR_REDUCE, SET_QUESTIONS_SAGA,
    GET_ANHELOS_SAGA, GET_ANSWERS_SAGA, GET_ALL_ENEA_QUESTIOS_SAGA, GET_ACTOR_SAGA,
    GET_INTEREST_ACTOR_SAGA, GET_CONTEXT_FEEDBACK_SAGA, GET_SATURATION_POINT_NOTIFICATION_SAGA,
    GET_TOOLS_SAGA,
    GET_CONFIG_SERVICE, GET_CONFIG_SAGA, SET_CONFIG_REDUCER,
    INIT_WATCH_TOOLS_SAGA,
    USER_TOOLS_SERVICE,
} from '../constantsGlobal'

import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'

import axios from 'axios'



// import firebase from "firebase";

// import { rsf } from '../utils/config'
import { rsf, messaging } from '../utils/config'



function* logIn(action) {
    const questios = yield call(axios.post, LOGIN_SERVICE, {
        codigo: action.payload.code
    });

    if (questios.data) {
        yield put({
            type: LOGIN_REDUCE, payload: true
        });
        yield put({
            type: SET_ACTOR_REDUCE, payload: questios.data
        });
        yield put({
            type: SET_QUESTIONS_SAGA, payload: questios.data.typeActor
        });
        yield put({
            type: GET_ANSWERS_SAGA, payload: questios.data.id
        });
        yield put({
            type: GET_INTEREST_ACTOR_SAGA, payload: questios.data.id
        });

        yield put({
            type: GET_ANHELOS_SAGA
        });

        yield put({
            type: GET_TOOLS_SAGA
        });

        yield put({
            type: GET_CONFIG_SAGA
        });

        yield put({
            type: GET_SATURATION_POINT_NOTIFICATION_SAGA, payload: questios.data.id
        });

        yield put({
            type: INIT_WATCH_TOOLS_SAGA
        });

        if (questios.data.p_team_actors && questios.data.p_team_actors.length > 0) {
            yield put({
                type: GET_CONTEXT_FEEDBACK_SAGA, payload: questios.data.p_team_actors.map(ta => ta.idTeam)
            });
        }

        if (!questios.data.eneatipo || questios.data.eneatipo === 0) {
            yield put({
                type: GET_ALL_ENEA_QUESTIOS_SAGA
            });
        }

        yield put({
            type: "GET_CURRENT_TOKEN_AUTORIZATION"
        });

    }

}



function* getActor(action) {
    const questios = yield call(axios.post, LOGIN_SERVICE, {
        codigo: action.payload
    });

    yield put({
        type: SET_ACTOR_REDUCE, payload: questios.data
    });

}


function* getConfig() {

    const config = yield rsf.database.read(GET_CONFIG_SERVICE);
    console.log(config)
    yield put({
        type: SET_CONFIG_REDUCER, payload: config
    });

}



function* putNewTokenNotification({ payload }) {
    const { actor } = yield select(state => state.diagnosis)
    yield call(rsf.database.patch, `${USER_TOOLS_SERVICE}/${actor.id}`, {
        tokenId: payload
    });
}

function* getCurrentTokenNotification({ payload }) {
    try {
        const currentToken = yield messaging.getToken();
        // console.log("currentToken", currentToken)
        if (currentToken) {
            yield put({
                type: "PUT_NEW_TOKEN_AUTORIZATION", payload: currentToken
            });
        }

    } catch (error) {
        console.log(error)
    }

}


export function* logInSaga() {
    yield takeEvery(LOGIN_SAGA, logIn);
}

export function* getActorSaga() {
    yield takeEvery(GET_ACTOR_SAGA, getActor);
}


export function* getConfigSaga() {
    yield takeLatest(GET_CONFIG_SAGA, getConfig);
}

export function* putNewTokenNotificationSaga() {
    yield takeLatest("PUT_NEW_TOKEN_AUTORIZATION", putNewTokenNotification);
}

export function* getCurrentTokenNotificationSaga() {
    yield takeLatest("GET_CURRENT_TOKEN_AUTORIZATION", getCurrentTokenNotification);
}



// export function* getAuthTokenNotificationSaga() {
//     yield takeLatest(GET_CONFIG_SAGA, syncUpdateRefreshToken);
// }



// function* syncUpdateRefreshToken() {
//     const { actor } = yield select(state => state.diagnosis)

//     messaging.onTokenRefresh(() => {
//         messaging.getToken().then((refreshedToken) => {
//             console.log('Token refreshed.');
//             // Indicate that the new Instance ID token has not yet been sent to the
//             // app server.
//             setTokenSentToServer(false);
//             // Send Instance ID token to app server.
//             sendTokenToServer(refreshedToken);
//             // ...
//         }).catch((err) => {
//             console.log('Unable to retrieve refreshed token ', err);
//             showToken('Unable to retrieve refreshed token ', err);
//         });
//     });

//     yield fork(rsf.database.sync, `${USER_TOOLS_SERVICE}/${actor.id}`, {
//         successActionCreator: (payload) => ({
//             type: SET_USER_TOOLS_STATE_SAGA, payload
//         })
//     })
// }








