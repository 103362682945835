import React, { Component } from 'react';

class Redirect extends Component {
    

    componentDidMount() { 
        const { currentHistory, route } = this.props;
        currentHistory.push(route);
    }


    render() {
        return (<div/>);
    }
}


export default Redirect;
