import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment'
import DayOfWeekItem from './dayOfWeekItem'

import { setCurrentDateScheduller } from '../../actions/actions';

class DaysOfWeek extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dow: []
        }
        this.setCurrDateScheduler = this.setCurrDateScheduler.bind(this);
        this.buildDaysOfWeek = this.buildDaysOfWeek.bind(this);
    }

    componentWillMount() {
        const { weekNumber } = this.props;
        this.buildDaysOfWeek(weekNumber);
    }

    buildDaysOfWeek(weekNumber) {
        let today = moment().week(weekNumber);

        let from_date = moment(today).startOf('week').add(1, 'd');
        let to_date = moment(today).endOf('week').add(2, 'd');

        let currDate = from_date.format();
        let dow = [];
        while (!to_date.isSame(currDate, 'day')) {
            dow.push(currDate);
            currDate = moment(currDate).add(1, 'd').format();
        }
        this.setState({ dow });
    }

    // componentWillUpdate(next) {
    //     const { currentDateScheduler } = this.props;


    //     let scheduler = next.actorTools ? Object.assign({}, next.actorTools.scheduler) : {};
    //     let currTasksObj = Object.assign({}, scheduler[currentDateScheduler]);
    //     let currentTasksDay = Object.keys(currTasksObj)

    //     if (next.currentDateScheduler !== currentDateScheduler ||
    //         this.state.currentTasksDay.length !== currentTasksDay.length) {
    //         this.buildTasks(next.currentDateScheduler);
    //     }
    // }

    componentWillUpdate(next) {
        const { currentDateScheduler } = this.props;
     

        if (next.currentDateScheduler !== currentDateScheduler ) {
            let weekNumber = moment(next.currentDateScheduler).week();
            if(moment(next.currentDateScheduler).format("d") === "0"){
                weekNumber = moment(next.currentDateScheduler).subtract(1, 'd').week();
            }
            this.buildDaysOfWeek(weekNumber);
        }
    }


    setCurrDateScheduler(item) {
        const { setCurrentDateScheduller } = this.props;
        setCurrentDateScheduller(moment(item).format().substring(0, 10));
    }



    render() {
        return (
            <>
                {this.state.dow.map(item =>
                    <DayOfWeekItem day={item} onClick={() => this.setCurrDateScheduler(item)} />
                )}
            </>
        )
    }
}


const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    actorTools: state.utils.actorTools,
    currentDateScheduler: state.utils.currentDateScheduler
});
const mapDispatchToProps = dispatch => bindActionCreators({
    setCurrentDateScheduller
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(DaysOfWeek);
