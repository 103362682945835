import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';


import FA from 'react-fontawesome';

import moment from 'moment';

// import ApiCalendar from 'react-google-calendar-api';


import { putSaturationPointNotification, setAlert } from '../../actions/actions'

import { eventMarkup } from '../../constantsGlobal'


class SaturationPointNotifications extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timePickervalue: moment(new Date()),
            pointsNotification: [
                // { "hour": "9", "hourText": "9:00 am", "date": "2020-05-27T14:00:24.913Z", "clout": 2 }, { "hour": "10", "hourText": "10:00 am", "date": "2020-05-27T15:00:24.913Z", "clout": 1 }, { "hour": "15", "hourText": "3:00 pm", "date": "2020-05-27T20:00:24.914Z", "clout": 1.3 }, { "hour": "18", "hourText": "6:00 pm", "date": "2020-05-27T23:00:24.914Z", "clout": 1.7 }
            ],
            schedulePointsNotification: []
        };

        this.currDate = React.createRef()

        this.addPoint = this.addPoint.bind(this);
        this.removePoint = this.removePoint.bind(this);
        this.savePoints = this.savePoints.bind(this);

    }

    componentWillMount() {
        const { pointsNotification } = this.props;

        this.setState({
            pointsNotification: pointsNotification.reduce((a, b, i, arr) => {
                let _a = a;
                let rangeH = _a.find(item => Number(b.hour) + 1 === Number(item.hour) || Number(b.hour) - 1 === Number(item.hour));
                if (!rangeH) {
                    _a.push(b)
                }
                return a;
            }, []).slice(0, 3).sort((a, b) => a.hour - b.hour)
        })
    }

    addPoint() {
        let pointsNotification = Object.assign([], this.state.pointsNotification);

        pointsNotification.push({
            "hour": this.state.timePickervalue.format('H'),
            "hourText": this.state.timePickervalue.format('hh:00 a'),
            "clout": 10
        });

        this.setState({
            pointsNotification
        });

    }
    removePoint(index) {
        let pointsNotification = Object.assign([], this.state.pointsNotification);
        pointsNotification.splice(index, 1)

        this.setState({
            pointsNotification
        });

    }

    savePoints() {
        const { putSaturationPointNotification, actor } = this.props;

        this.addEvent()
            .then((item) =>
                item === "login" ? null :
                    putSaturationPointNotification(this.state.pointsNotification.map(item => ({
                        hourPoint: item.hour,
                        idActor: actor.id
                    })))
            );



    }

    addEvent() {
        return new Promise((_resolve) => {

            // if (ApiCalendar.sign) {
                let itemsCalendar = Object.assign([], this.state.pointsNotification.map(item => item.hour));

                this.fnPromiseRecursive(_resolve, itemsCalendar.pop(), itemsCalendar);
            // } else {
            //     ApiCalendar.handleAuthClick();
            //     _resolve("login");
            // }
        })

    }

    fnPromiseRecursive(pResolve, hour, array) {
        return new Promise((resolve) => {
            this.fnCreateSchedules(hour, resolve)
        }).then(array[0] ?
            () => this.fnPromiseRecursive(pResolve, array.shift(), array) :
            () => pResolve()
        )
    }


    fnCreateSchedules(hour, resolve) {

        let _eventMarkup = Object.assign({}, { ...eventMarkup });

        let calendar = new Date();
        calendar.setDate(calendar.getDate() + 1);
        calendar.setHours(hour);
        calendar.setMinutes(0);

        let calendarEnd = new Date(calendar);
        calendarEnd.setMinutes(15);

        _eventMarkup.start.dateTime = moment(calendar).format();
        _eventMarkup.end.dateTime = moment(calendarEnd).format();

        // let request = ApiCalendar.createEvent(_eventMarkup);
        // request.execute((event) => {
        //     resolve('Event created: ' + event.htmlLink)
        // });
        resolve('Event created: ')
    }


    render() {
        return (
            <div className="content-saturation-point-notification">
                <div className="content-saturation-title">
                    <h3>Puntos de saturación</h3>
                    <p>Felicidades!<br />
                        Has identificado tus momentos de saturación, en los próximos 15 días, te estaremos enviando notificaciones en los momentos señalado a continuación:
                    </p>
                </div>
                <div className="saturation-point-main">
                    <h4>Notificaciones</h4>
                    <div className="horizontal-content" >
                        <label>Nueva notificación</label>
                        <TimePicker value={this.state.timePickervalue} onChange={(value) => this.setState({ timePickervalue: value })}
                            showSecond={false} showMinute={false} format={'h:00 a'} use12Hours={true} />
                        <span className="callback-doot" onClick={this.addPoint}> <FA name={"plus"} /> </span>
                    </div>
                    <div className="saturation-point-content-items">
                        {this.state.pointsNotification.map((item, index) => (
                            <div className="horizontal-content">
                                <span>{item.hourText}</span>
                                <span className="callback-doot" onClick={() => this.removePoint(index)}> <FA name={"remove"} /> </span>
                            </div>
                        ))}
                    </div>
                </div>
                <button className="callback" onClick={() => this.savePoints()} >Agendar</button>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    // pointsNotification: state.saturation.pointsNotification
    actor: state.diagnosis.actor
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        putSaturationPointNotification,
        setAlert
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(SaturationPointNotifications);