import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BreathIndicatorPath from './breathIndicatorPath'
import { indPaths } from '../../constantsGlobal'

import { breathAction, breathActionStop } from '../../actions/actions'


class breathIndicator extends Component {
   

    componentWillUnmount() {
        const { breathActionStop } = this.props;
        breathActionStop();
    }

    render() {
        const { breathAction, breatStatus } = this.props;
        const { limitTime } = this.props;
        return (
            <div className="content-breath-indicator">

                {/* transform:  this.getScale() */}

                {/* className={this.getClassBreathAnimation()} */}
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg"
                    className={`breath`} act={breatStatus.stepAction}
                    style={{
                        animationDuration: `${breatStatus.stepTime}s`
                    }}
                >

                    {indPaths.map((path, indx) => (
                        <BreathIndicatorPath key={`path_${indx}`} path={path} />
                    ))}

                    {/* <path fill="#FF0066" d={this.state.path} transform="translate(100 100)" /> */}
                </svg>
                <div>
                    {(breatStatus && breatStatus.run) &&
                        <span className="breath-time">
                            {breatStatus.stepCount}
                            <small>S</small>
                        </span>
                    }
                    {(breatStatus && !breatStatus.run) &&
                        <span className="breath-time" onClick={() => breathAction(limitTime * 60)} >Iniciar</span>
                    }

                    <span className="breath-action">{breatStatus ? breatStatus.stepName : ""}</span>
                </div>



            </div>
        )
    }
}




const mapStateToProps = state => ({
    actor: state.diagnosis.actor,
    breatStatus: state.breathTool.breatStatus
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    breathAction,
    breathActionStop
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(breathIndicator);
